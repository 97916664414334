import { Chip, Hidden, Stack, Typography } from '@mui/material'
import { Fixture, Game } from 'modules/api/types'
import React from 'react'
import { differenceInDays, formatDistance } from 'date-fns'
import { SportsSoccer } from '@mui/icons-material'

interface IProps {
  fixture: Fixture
  game: Game
}
const FixturePredictionStatus: React.FC<IProps> = ({ fixture, game }) => {
  const isFixtureLocked = game.predictionRules.lockedFixtures.includes(fixture.id)

  const isFixtureLockingSoon = () =>
    !isFixtureLocked && differenceInDays(new Date(game.predictionRules.deadlines.fixtures[fixture.id]), new Date()) < 7

  const getLocksInText = (): string => {
    return formatDistance(new Date(), new Date(game.predictionRules.deadlines.fixtures[fixture.id]))
  }

  const renderPredictionPoints = (): React.ReactElement => {
    if (fixture.fixtureStatus === 'NotStarted') return <Typography variant='body2'>Not played</Typography>

    const totalPoints = !fixture.prediction?.predictionOutcome
      ? 0
      : fixture.prediction.outcomePointsAcquired + fixture.prediction.scorerPointsAcquired

    let outcomeTag = <></>

    if (fixture.prediction?.predictionOutcome) {
      let outcomeTagColor: 'default' | 'primary' | 'success' = 'default'

      if (fixture.prediction?.predictionOutcome === 'CO') outcomeTagColor = 'primary'
      else if (fixture.prediction?.predictionOutcome === 'ES') outcomeTagColor = 'success'

      outcomeTag = <Chip size='small' color={outcomeTagColor} label={fixture.prediction?.predictionOutcome} />
    }

    const totalGoals = (fixture.prediction?.scorerNormalGoals ?? 0) + (fixture.prediction?.scorerPenaltyGoals ?? 0)

    return (
      <Stack direction='row' alignItems='center' gap={1}>
        <Typography variant='body2'>
          <strong>{totalPoints} points</strong>
        </Typography>
        <Hidden smDown>
          {outcomeTag}
          {totalGoals > 0 && (
            <Stack direction='row' alignItems='center'>
              {[...Array(totalGoals)].map((x, i) => (
                <SportsSoccer key={i} color='success' fontSize='small' />
              ))}
            </Stack>
          )}
        </Hidden>
      </Stack>
    )
  }

  // Unlocked
  return (
    <Stack direction='row' justifyContent='center' gap={1} width='100%' alignItems='center' py={0.5} color='primary.main'>
      {!isFixtureLocked && (
        <>
          {isFixtureLockingSoon() && (
            <Typography color='error' className='blink'>
              Locks in {getLocksInText()}
            </Typography>
          )}
          {!isFixtureLockingSoon() && <Typography variant='caption'>Locks in {getLocksInText()}</Typography>}
        </>
      )}
      {isFixtureLocked && (
        <>
          <Stack flexGrow={1} flexBasis={0} alignItems='end'>
            <Typography variant='body2'>Prediction</Typography>
          </Stack>
          <Stack width={'100px'} justifyContent='center' flexShrink={0} direction='row' gap='19px'>
            <Typography variant='body2'>{fixture.prediction?.homeTeamScore ?? ' '}</Typography>
            <Typography variant='body2'>-</Typography>
            <Typography variant='body2'>{fixture.prediction?.awayTeamScore ?? ' '}</Typography>
          </Stack>
          <Stack flexGrow={1} flexBasis={0} direction='row' gap={1}>
            {renderPredictionPoints()}
          </Stack>
        </>
      )}
    </Stack>
  )
}

export default FixturePredictionStatus
