import { Paper, Stack, Theme, Typography, useMediaQuery } from '@mui/material'
import { StepperButtons } from 'components'
import { NewGameStep2Value, StepProps } from '../types'
import GameOptionField from './GameOptionField'

const NewGameStep2: React.FC<StepProps<NewGameStep2Value | undefined>> = ({
  stepValue,
  onChange,
  onNext,
  onPrevious,
}: StepProps<NewGameStep2Value | undefined>) => {
  const isSmDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'))

  const onOptionValueChange = (code: string, newValue: string) => {
    if (!stepValue) return

    const newOptions = [...stepValue.options]
    const option = newOptions.find((x) => x.code === code)
    if (!option) return
    option.value = newValue
    onChange({ options: newOptions })
  }

  return (
    <>
      <Stack gap={2}>
        {stepValue &&
          stepValue.options.map((option) => (
            <Paper key={option.code} sx={{ p: 2 }}>
              <Stack direction={isSmDown ? 'column' : 'row'} gap={1} justifyContent='space-between' alignItems='center'>
                <Stack flexGrow={1} gap={1}>
                  <Typography variant='h6'>{option.name}</Typography>
                  <Typography variant='body2'>{option.description}</Typography>
                </Stack>
                <Stack flexShrink={0} width='120px' alignItems='center'>
                  <GameOptionField value={option.value} onChange={(newValue) => onOptionValueChange(option.code, newValue)} />
                </Stack>
              </Stack>
            </Paper>
          ))}
      </Stack>
      <StepperButtons
        onPreviousClick={() => onPrevious && onPrevious()}
        onNextClick={() => onNext && onNext()}
        previousText={isSmDown ? 'Details' : 'Game Details'}
        nextText={isSmDown ? 'Points' : 'Game Points'}
      />
    </>
  )
}

export default NewGameStep2
