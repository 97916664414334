import { Alert, AlertTitle, Container, Stack, Theme, useMediaQuery } from '@mui/material'
import { ParticipantViewSelect } from 'components'
import { usePredictorApi } from 'modules/api'
import { getGroupPredictions, getParticipantGroupPredictions, getTournamentGroups } from 'modules/api/requests'
import { TournamentGroups } from 'modules/api/types'
import { ActiveGamePageHeader, useActiveGame } from 'modules/games'
import { PREDICTOR_ROUTE_COLLECTION } from 'predictor-constants'
import { useEffect, useState } from 'react'
import { Group } from '../components'
import { GroupView } from '../types'

type viewParticipantType = 'actual' | string | undefined

const Groups = () => {
  const uag = useActiveGame()
  const { game } = uag
  const isSmDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'))
  const apiGetGroups = usePredictorApi<TournamentGroups>()
  const apiTournamentGroups = apiGetGroups.data
  const groups: GroupView[] =
    apiTournamentGroups === undefined
      ? []
      : Object.keys(apiTournamentGroups).map((groupName) => ({
          groupName,
          teams: apiTournamentGroups[groupName],
          positionOverride: apiTournamentGroups[groupName].some((t) => Boolean(t.positionOverride)),
          positionsConfirmed: apiTournamentGroups[groupName].every((t) => t.positionConfirmed),
        }))

  const apiGetGroupPredictions = usePredictorApi<TournamentGroups>()
  const apiGroupPredictions = apiGetGroupPredictions.data
  const calculatedGroupPredictions: GroupView[] =
    apiGroupPredictions === undefined
      ? []
      : Object.keys(apiGroupPredictions).map((groupName) => ({
          groupName,
          teams: apiGroupPredictions[groupName],
        }))

  const apiGetParticipantGroupPredictions = usePredictorApi<TournamentGroups>()
  const apiParticipantGroupPredictions = apiGetParticipantGroupPredictions.data
  const calculatedParticipantGroupPredictions: GroupView[] =
    apiParticipantGroupPredictions === undefined
      ? []
      : Object.keys(apiParticipantGroupPredictions).map((groupName) => ({
          groupName,
          teams: apiParticipantGroupPredictions[groupName],
        }))

  const [viewParticipant, setViewParticipant] = useState<viewParticipantType>('actual')

  useEffect(() => {
    if (!game) return
    apiGetGroups.requestWithParams((client) => getTournamentGroups(client, game.tournamentId))
    apiGetGroupPredictions.requestWithParams((client) => getGroupPredictions(client, game.id))

    if (!game.predictionRules.teamPredictionsLocked) setViewParticipant(game.userParticipant.id)
  }, [game])

  useEffect(() => {
    if (!game || !viewParticipant) return
    if (viewParticipant === 'actual' || viewParticipant === game.userParticipant.id) return // Default behavior

    apiGetParticipantGroupPredictions.requestWithParams((client) => getParticipantGroupPredictions(client, game.id, viewParticipant))
  }, [game, viewParticipant])

  return (
    <Container maxWidth='md' disableGutters={isSmDown}>
      <ActiveGamePageHeader useActiveGameState={uag} route={PREDICTOR_ROUTE_COLLECTION.groups} />
      {game && (
        <Stack gap={1}>
          {game.predictionRules.teamPredictionsLocked && (
            <Stack alignSelf='center'>
              <ParticipantViewSelect game={game} includeRealOption={true} selectedView={viewParticipant} onChange={setViewParticipant} />
            </Stack>
          )}
          {!game.predictionRules.teamPredictionsLocked && (
            <Alert severity='info'>
              <AlertTitle>Your group predictions</AlertTitle>
              {game.predictionRules.groupPredictionsAllowed && 'Select the group positions before the tournament starts.'}
              {!game.predictionRules.groupPredictionsAllowed &&
                'You are seeing the group predictions according to your fixture predictions.'}
            </Alert>
          )}
          <Stack gap={3}>
            {groups && groups.length > 0 && calculatedGroupPredictions && (
              <>
                {groups.map((group) => (
                  <Group
                    key={group.groupName}
                    game={game}
                    group={group}
                    calculatedGroupPrediction={
                      viewParticipant === game.userParticipant.id || viewParticipant === 'actual'
                        ? calculatedGroupPredictions.find((x) => x.groupName === group.groupName)
                        : calculatedParticipantGroupPredictions.find((x) => x.groupName === group.groupName)
                    }
                    showRealResults={viewParticipant === 'actual'}
                  />
                ))}
              </>
            )}
          </Stack>
        </Stack>
      )}
    </Container>
  )
}

export default Groups
