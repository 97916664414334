import { apiGet } from 'modules/api/predictor-api-client'
import { ClientWithController } from '../types'

const endpointBase = '/tournament'
const endpoint = (tournamentId?: string, suffix?: string) =>
  `${endpointBase}${tournamentId ? `/${tournamentId}` : ''}${suffix ? `/${suffix}` : ''}`

const getActiveTournaments = async (client: ClientWithController) => await apiGet(client, endpoint())
const getTournamentGroups = async (client: ClientWithController, tournamentId: string) =>
  await apiGet(client, endpoint(tournamentId, 'group'))
const getTournamentGameConfigurationOptions = async (client: ClientWithController, tournamentId: string) =>
  await apiGet(client, endpoint(tournamentId, 'gameConfigurationOptions'))
const getTournamentTeams = async (client: ClientWithController, tournamentId: string) =>
  await apiGet(client, endpoint(tournamentId, 'teams'))
const getTournamentStages = async (client: ClientWithController, tournamentId: string) =>
  await apiGet(client, endpoint(tournamentId, 'stage'))
const getTournamentPlayers = async (client: ClientWithController, tournamentId: string) =>
  await apiGet(client, endpoint(tournamentId, 'players'))

export {
  getActiveTournaments,
  getTournamentGroups,
  getTournamentGameConfigurationOptions,
  getTournamentTeams,
  getTournamentStages,
  getTournamentPlayers,
}
