import { TextField } from '@mui/material'
import { FormFieldProps } from 'types/form'
import React from 'react'

const GameNameField: React.FC<FormFieldProps> = ({ field, onChange }) => {
  return (
    <TextField
      id='game-name'
      label='Game Name'
      variant='outlined'
      error={!field.isValid}
      helperText={field.isValid && field.validationErrors[0]}
      required
      value={field.value}
      onChange={(e) => onChange(e.target.value)}
    />
  )
}

export default GameNameField
