import { Save } from '@mui/icons-material'
import { Button, Paper, Stack } from '@mui/material'
import { LoadingBox } from 'components'
import { usePredictorContext } from 'context/PredictorContext'
import { usePredictorApi } from 'modules/api'
import { getPredictedScorers, getTournamentPlayers, getTournamentTeams, setPredictedScorers } from 'modules/api/requests'
import { Game, Team, Scorer, SetScorerRequest, Player } from 'modules/api/types'
import React, { useEffect, useState } from 'react'
import { isSuccessStatusCode } from 'utils/request-utils'
import ScorerField from './ScorerField'

interface IProps {
  game: Game
}

const ScorersForm: React.FC<IProps> = ({ game }) => {
  const { refreshParticipantStatus } = usePredictorContext()

  const apiGetTeams = usePredictorApi<Team[]>({
    apiFunc: (client) => getTournamentTeams(client, game.tournamentId),
    errorMessage: 'Could not load teams.',
  })
  const teams = apiGetTeams.data?.sort((a, b) => a.name.localeCompare(b.name))

  const apiGetPlayers = usePredictorApi<Player[]>({
    apiFunc: (client) => getTournamentPlayers(client, game.tournamentId),
    errorMessage: 'Could not load tournament players.',
  })
  const players = apiGetPlayers.data?.map((x) => ({ ...x, team: teams?.find((t) => t.code === x.teamCode) })) ?? []

  const apiGetPredictedScorers = usePredictorApi<Scorer[]>({
    apiFunc: (client) => getPredictedScorers(client, game.id),
    errorMessage: 'Could not load predicted scorers.',
  })
  const existingScorers = apiGetPredictedScorers.data

  const apiSetPredictedScorers = usePredictorApi({
    successMessage: 'Scorers set successfully',
    errorMessage: 'Could not set scorers. {{error}}',
  })

  const [scorers, setScorers] = useState<Scorer[]>([])

  const onTeamChange = (i: number, teamCode: string) => {
    const newScorers = [...scorers]
    const foundTeam = teams?.find((x) => x.code === teamCode)
    if (!foundTeam) return
    newScorers[i].team = { ...foundTeam }
    setScorers(newScorers)
  }

  const onScorerChange = (i: number, scorer: string) => {
    const newScorers = [...scorers]
    newScorers[i].scorerName = scorer
    setScorers(newScorers)
  }

  const saveScorers = async () => {
    const request: SetScorerRequest[] = scorers
      .map((x) => ({ teamCode: x.team.code, scorerName: x.scorerName }))
      .filter((x) => x.scorerName !== '' && x.teamCode !== '')
    const response = await apiSetPredictedScorers.requestWithParams((client) => setPredictedScorers(client, game.id, request))
    if (isSuccessStatusCode(response)) {
      apiGetPredictedScorers.request()
      refreshParticipantStatus()
    }
  }

  useEffect(() => {
    if (!existingScorers) return

    const newScorers: Scorer[] = [...Array(game.predictionRules.maxNumberOfScorers)].map((x, i) => {
      if (existingScorers.length < i + 1) return { scorerName: '', team: { code: '', name: '' } }
      return { ...existingScorers[i] }
    })

    setScorers(newScorers)
  }, [existingScorers])

  if (apiGetTeams.isLoading || apiGetPredictedScorers.isLoading) return <LoadingBox />

  return (
    <Stack gap={1} mt={2}>
      {teams &&
        scorers &&
        players &&
        [...Array(game.predictionRules.maxNumberOfScorers)].map((x, i) => (
          <Paper key={`scorer-${i}`} sx={{ p: 2 }}>
            <ScorerField
              teams={teams}
              teamCode={scorers.length > i ? scorers[i].team.code : ''}
              scorerName={scorers.length > i ? scorers[i].scorerName : ''}
              onTeamChange={(team) => onTeamChange(i, team)}
              onScorerChange={(scorer) => onScorerChange(i, scorer)}
              players={players}
            />
          </Paper>
        ))}
      <Button variant='contained' sx={{ width: '250px', alignSelf: 'center', mt: 2 }} endIcon={<Save />} onClick={() => saveScorers()}>
        Save scorers
      </Button>
    </Stack>
  )
}

export default ScorersForm
