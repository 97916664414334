import { MenuOpen } from '@mui/icons-material'
import { Alert, AlertTitle, AppBar, Box, Drawer, Hidden, Stack, Toolbar, Typography } from '@mui/material'
import { Link } from 'components'
import { usePredictorContext } from 'context/PredictorContext'
import { useActiveGame } from 'modules/games'
import { PREDICTOR_ROUTE_COLLECTION, STYLE_SETTINGS } from 'predictor-constants'
import React from 'react'
import { useNavigate } from 'react-router-dom'

const RightSidebar: React.FC = () => {
  const { rightSidebarTitleRef, rightSidebarContentRef, rightSidebarOpen, setRightSidebarOpen } = usePredictorContext()
  const { extendedPredictionsStatus } = useActiveGame()
  const navigate = useNavigate()

  const toggleRightSidebar = (open: boolean) => {
    setRightSidebarOpen(open)
  }

  const drawerContent = (
    <>
      <AppBar position='sticky'>
        <Toolbar variant='dense'>
          <Box width='100%' ref={rightSidebarTitleRef}>
            <Typography variant='h6' color='emphasis' textAlign='center'>
              Focus
            </Typography>
          </Box>
        </Toolbar>
      </AppBar>

      <Stack sx={{ mb: '48px', mt: 1 }} gap={1}>
        <Stack gap={1} ref={rightSidebarContentRef}></Stack>
        {extendedPredictionsStatus && extendedPredictionsStatus.isMissingUnlockedPredictions && (
          <Alert severity='warning' sx={{ mx: 1 }}>
            <AlertTitle>You have pending predictions.</AlertTitle>
            <Stack gap={0.5}>
              {extendedPredictionsStatus.isMissingUnlockedFixturePredictions && (
                <Link onClick={() => navigate(PREDICTOR_ROUTE_COLLECTION.fixtures.path)}>
                  Missing {extendedPredictionsStatus.incompleteUnlockedFixturePredictions.length} fixture predictions
                </Link>
              )}
              {extendedPredictionsStatus.isMissingUnlockedStagePredictions && (
                <Link onClick={() => navigate(PREDICTOR_ROUTE_COLLECTION.knockout.path)}>Missing knockout predictions.</Link>
              )}
              {extendedPredictionsStatus.isMissingUnlockedScorerPredictions && (
                <Link onClick={() => navigate(PREDICTOR_ROUTE_COLLECTION.scorers.path)}>
                  Missing {extendedPredictionsStatus.missingUnlockedScorerPredictions} scorer predictions.
                </Link>
              )}
            </Stack>
          </Alert>
        )}
      </Stack>
    </>
  )

  return (
    <>
      <Hidden xlUp>
        {!rightSidebarOpen && <MenuOpen onClick={() => toggleRightSidebar(true)} sx={{ mr: 1 }} />}
        {rightSidebarOpen && (
          <MenuOpen onClick={() => toggleRightSidebar(false)} color='secondary' sx={{ transform: 'scaleX(-1)', mr: 1 }} />
        )}
        <Drawer
          anchor='right'
          sx={{ '>.MuiDrawer-paper': { width: STYLE_SETTINGS.RIGHT_SIDEBAR_WIDTH } }}
          ModalProps={{
            keepMounted: true,
          }}
          open={rightSidebarOpen}
          onClose={() => setRightSidebarOpen(false)}
        >
          {drawerContent}
        </Drawer>
      </Hidden>
      <Hidden xlDown>
        <Drawer anchor='right' variant='permanent' sx={{ '>.MuiDrawer-paper': { width: STYLE_SETTINGS.RIGHT_SIDEBAR_WIDTH } }}>
          {drawerContent}
        </Drawer>
      </Hidden>
    </>
  )
}

export default RightSidebar
