import { Container, Stack, Theme, useMediaQuery } from '@mui/material'
import { StepperButtons } from 'components'
import { Tournament } from 'modules/api/types'
import { useForm } from 'modules/form'
import { FormEvent } from 'react'
import { validateRequired } from 'types/form'
import { NewGameStep1Value, StepProps } from '../types'
import GameNameField from './GameNameField'
import TournamentSelect from './TournamentSelect'

interface IProps extends StepProps<NewGameStep1Value> {
  tournaments: Tournament[]
}

const NewGameStep1: React.FC<IProps> = ({ stepValue, onChange, onNext, tournaments }: IProps) => {
  const isSmDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'))

  const form = useForm({
    gameName: { defaultValue: stepValue.gameName, validations: [validateRequired] },
    tournamentId: {
      defaultValue: stepValue.tournamentId,
      validations: [(value, options) => validateRequired(value, { ...options, fieldDisplayName: 'Tournament' })],
    },
  })
  const { tournamentId, gameName } = form.fields

  const onSubmit = async (e: FormEvent) => {
    e.preventDefault()
    onChange({ tournamentId: tournamentId.value as string, gameName: gameName.value as string })
    onNext && onNext()
  }

  return (
    <Container maxWidth='xs' sx={{ mt: 2 }}>
      <form onSubmit={onSubmit}>
        <Stack gap={3}>
          <GameNameField field={gameName} onChange={form.set.gameName} />
          <TournamentSelect field={tournamentId} onChange={form.set.tournamentId} tournaments={tournaments} />
          <StepperButtons hidePrevious nextIsSubmit nextDisabled={!form.isValid} nextText={isSmDown ? 'Options' : 'Game Options'} />
        </Stack>
      </form>
    </Container>
  )
}

export default NewGameStep1
