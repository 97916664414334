import { TabContext, TabList, TabPanel } from '@mui/lab'
import { Box, Container, Tab, useMediaQuery, Theme, Stack, Divider, Typography } from '@mui/material'
import { PageTitle } from 'components'
import { usePredictorContext } from 'context/PredictorContext'
import { usePredictorApi } from 'modules/api'
import { getActiveTournaments, getTournamentGameConfigurationOptions, createGame } from 'modules/api/requests'
import { GameConfigurationOptions, GameInsertRequest, GameOptions, GamePointDefinition, Tournament } from 'modules/api/types'
import { useConfirmationModalContext } from 'modules/ui'
import { PREDICTOR_ROUTE_COLLECTION } from 'predictor-constants'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { isSuccessStatusCode } from 'utils/request-utils'
import { NewGameStep1, NewGameStep2, NewGameStep3 } from '../components'
import { NewGameStep1Value, NewGameStep2Value, NewGameStep3Value } from '../types'

const NewGame = () => {
  const isSmDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'))
  const { refreshUser } = usePredictorContext()
  const { showConfirm } = useConfirmationModalContext()

  const apiGetTournaments = usePredictorApi<Tournament[]>({
    apiFunc: (client) => getActiveTournaments(client),
    errorMessage: 'Could not get tournament list.',
  })
  const tournaments = apiGetTournaments.data

  const apiCreateGame = usePredictorApi({ successMessage: 'Game created.' })
  const navigate = useNavigate()

  const [currentTab, setCurrentTab] = useState('1')

  const handleTabChange = (event: React.SyntheticEvent<Element, Event>, value: string) => {
    setCurrentTab(value)
  }

  const [step1Value, setStep1Value] = useState<NewGameStep1Value>({ gameName: '', tournamentId: '' })
  const [step2Value, setStep2Value] = useState<NewGameStep2Value>()
  const [step3Value, setStep3Value] = useState<NewGameStep3Value>()

  const apiGetConfigOptions = usePredictorApi<GameConfigurationOptions>()
  const gameConfigOptions = apiGetConfigOptions.data

  const renderSummaryPoint = (label?: string, value?: string | number): React.ReactElement => {
    return (
      <Stack direction='row' gap={1} alignItems='center' key={label}>
        <Typography variant='subtitle2' minWidth='50px' flexShrink={0} maxWidth='70%'>
          {label}
        </Typography>
        <Typography variant='body2' flexGrow={1}>
          {typeof value === 'number' || (!['true', 'false'].includes(value as string) && value)}
          {value === 'true' && 'Yes'}
          {value === 'false' && 'No'}
        </Typography>
      </Stack>
    )
  }

  const renderConfirmSummary = (gameInsertRequest: GameInsertRequest): React.ReactElement => {
    return (
      <Stack gap={2} width='100%'>
        <Typography variant='body1'>Are you sure you want to create a new game with the details below?</Typography>
        <Typography variant='subtitle1'>Details</Typography>
        {renderSummaryPoint('Name', gameInsertRequest.name)}
        {renderSummaryPoint('Tournament', tournaments?.find((t) => t.id === gameInsertRequest.tournamentId)?.name)}
        <Divider />
        <Typography variant='subtitle1'>Options</Typography>
        {Object.keys(gameInsertRequest.options).map((optionKey) =>
          renderSummaryPoint(step2Value?.options.find((x) => x.code === optionKey)?.name, gameInsertRequest.options[optionKey]),
        )}
        <Divider />
        <Stack>
          <Typography variant='subtitle1'>Points</Typography>
        </Stack>

        {renderSummaryPoint('Code', 'Value (Leader Bonus)')}
        {gameInsertRequest.pointDefinitions.map((point) =>
          renderSummaryPoint(
            point.pointType,
            point.isEnabled ? `${point.pointValue}${point.bonusForPointLeader > 0 ? ` (${point.bonusForPointLeader})` : ''}` : 'Disabled',
          ),
        )}
      </Stack>
    )
  }

  const getGameInsertRequest = (): GameInsertRequest | undefined => {
    if (!step2Value || !step3Value) return undefined

    return {
      tournamentId: step1Value.tournamentId,
      name: step1Value.gameName,
      options: step2Value.options.reduce((prev, curr) => {
        const newObj: GameOptions = { ...prev }
        newObj[curr.code] = curr.value
        return newObj
      }, {} as GameOptions),
      pointDefinitions: step3Value.points.map(
        (x) =>
          ({
            pointType: x.code,
            isEnabled: x.isEnabled,
            pointValue: x.pointValue,
            bonusForPointLeader: x.bonusForPointLeader,
          } as GamePointDefinition),
      ),
    }
  }

  const handleCreateGame = async () => {
    const gameInsertRequest = getGameInsertRequest()
    if (!gameInsertRequest) return

    const confirmed = await showConfirm({
      title: 'Create game',
      renderMessage: () => renderConfirmSummary(gameInsertRequest),
      confirmText: 'Create game',
    })

    if (!confirmed) return

    const response = await apiCreateGame.requestWithParams((client) => createGame(client, gameInsertRequest))
    if (isSuccessStatusCode(response)) {
      refreshUser()
      navigate(PREDICTOR_ROUTE_COLLECTION.gameSelection.path)
    }
  }

  const goToNextStep = async () => {
    switch (currentTab) {
      case '1':
        setCurrentTab('2')
        break
      case '2':
        setCurrentTab('3')
        break
      case '3':
        await handleCreateGame()
        break
    }
  }

  const goToPreviousStep = async () => {
    switch (currentTab) {
      case '2':
        setCurrentTab('1')
        break
      case '3':
        setCurrentTab('2')
        break
    }
  }

  const onStep1Change = (newValue: NewGameStep1Value) => {
    if (newValue.tournamentId !== step1Value.tournamentId) {
      setStep2Value(undefined)
      setStep3Value(undefined)
    }
    setStep1Value(newValue)
  }

  useEffect(() => {
    if (currentTab === '2')
      apiGetConfigOptions.requestWithParams((client) => getTournamentGameConfigurationOptions(client, step1Value.tournamentId))
  }, [currentTab])

  useEffect(() => {
    if (!gameConfigOptions) return

    if (!step2Value) setStep2Value({ options: gameConfigOptions.gameOptions.map((x) => ({ ...x, value: x.defaultValue })) })
    if (!step3Value) setStep3Value({ points: gameConfigOptions.gamePoints })
  }, [gameConfigOptions])

  return (
    <Container maxWidth='md' disableGutters>
      <PageTitle title={PREDICTOR_ROUTE_COLLECTION.newGame.title} icon={PREDICTOR_ROUTE_COLLECTION.newGame.icon} />

      <TabContext value={currentTab}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <TabList onChange={handleTabChange} centered>
            <Tab label={isSmDown ? '1. Details' : '1. Game Details'} value='1' />
            <Tab label={isSmDown ? '2. Options' : '2. Game Options'} value='2' disabled={currentTab === '1' || !step1Value.tournamentId} />
            <Tab label={isSmDown ? '3. Points' : '3. Game Points'} value='3' disabled={currentTab === '1' || !step1Value.tournamentId} />
          </TabList>
        </Box>
        <TabPanel value='1'>
          {tournaments && (
            <NewGameStep1
              stepValue={step1Value}
              onChange={onStep1Change}
              onNext={goToNextStep}
              onPrevious={goToPreviousStep}
              tournaments={tournaments}
            />
          )}
        </TabPanel>
        <TabPanel value='2'>
          <NewGameStep2
            stepValue={step2Value}
            onChange={(newValue) => setStep2Value(newValue)}
            onNext={goToNextStep}
            onPrevious={goToPreviousStep}
          />
        </TabPanel>
        <TabPanel value='3'>
          <NewGameStep3
            stepValue={step3Value}
            onChange={(newValue) => setStep3Value(newValue)}
            onNext={goToNextStep}
            onPrevious={goToPreviousStep}
          />
        </TabPanel>
      </TabContext>
    </Container>
  )
}

export default NewGame
