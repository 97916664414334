import { Autocomplete, FormControl, InputLabel, MenuItem, Select, Stack, TextField } from '@mui/material'
import { TeamName } from 'components'
import { Player, Team } from 'modules/api/types'
import React from 'react'

interface IProps {
  teams: Team[]
  teamCode: string
  scorerName: string
  players: Player[]
  onTeamChange: (teamCode: string) => void
  onScorerChange: (scorer: string) => void
}

const ScorerField: React.FC<IProps> = ({ teams, teamCode, scorerName, players, onTeamChange, onScorerChange }) => {
  return (
    <Stack sx={{ flexDirection: { xs: 'column', md: 'row' } }} gap={2}>
      <FormControl sx={{ minWidth: '200px', flexGrow: 1, flexBasis: 0 }}>
        <InputLabel>Team</InputLabel>
        <Select label='Team' value={teamCode} onChange={(e) => onTeamChange(e.target.value)}>
          {teams.map((team) => (
            <MenuItem key={team.code} value={team.code}>
              <TeamName team={team} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <Autocomplete
        disablePortal
        id='player'
        freeSolo
        options={players.filter((x) => x.teamCode === teamCode).map((x) => x.playerName)}
        onInputChange={(e, value) => {
          onScorerChange(value)
        }}
        onChange={(e, value) => {
          onScorerChange(value ? (value as string) : '')
        }}
        value={scorerName}
        componentsProps={{}}
        sx={{
          minWidth: '200px',
          flexGrow: 1,
          flexBasis: { xs: 0, md: 100 },
          '>.MuiFormControl-root>.MuiInputBase-root': { height: '59px' },
        }}
        renderInput={(params) => <TextField {...params} label='Scorer name' required />}
      />
    </Stack>
  )
}

export default ScorerField
