import { ArrowCircleRight } from '@mui/icons-material'
import { Alert, AlertTitle, Button, Container, Divider, Grid, Stack, Typography } from '@mui/material'
import { LoadingBox, PageTitle } from 'components'
import { usePredictorContext } from 'context/PredictorContext'
import { usePredictorApi } from 'modules/api'
import { getAdminGames } from 'modules/api/requests'
import { Game } from 'modules/api/types'
import { PREDICTOR_ROUTE_COLLECTION } from 'predictor-constants'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { GameSelectionAdminGame, GameSelectionUserGame } from '../components'

const GameSelection = () => {
  const { user } = usePredictorContext()
  const navigate = useNavigate()
  const apiGetAdminGames = usePredictorApi<Game[]>({ apiFunc: getAdminGames })

  const userGames = user?.gameParticipants.filter((x) => !x.isGameAdmin)
  const adminGames = apiGetAdminGames.data

  return (
    <Container maxWidth='md'>
      <PageTitle title={PREDICTOR_ROUTE_COLLECTION.gameSelection.title} icon={PREDICTOR_ROUTE_COLLECTION.gameSelection.icon} />

      {!user && <LoadingBox />}

      {user && user.gameParticipants.length === 0 && (
        <Alert severity='warning'>
          <AlertTitle>No assigned games</AlertTitle>
          You have no assigned games. You can create a new game or use an invite link.
        </Alert>
      )}

      {user && user.gameParticipants.length > 0 && (
        <>
          <Stack mt={1} mb={3} gap={1}>
            <Typography variant='h4'>Select active game</Typography>
            <Stack gap={1}>
              <Stack direction='row' gap={1}>
                <ArrowCircleRight />
                <Typography>All data shown in pages will reflect the active game selected here.</Typography>
              </Stack>
              <Stack direction='row' gap={1}>
                <ArrowCircleRight />
                <Typography>The active game is also visible in the main menu, if you have more than one active games.</Typography>
              </Stack>
            </Stack>
            <Divider />
          </Stack>

          <Stack gap={2}>
            {adminGames && adminGames.length > 0 && (
              <>
                <Typography variant='h6' color='text.secondary'>
                  Administered Games
                </Typography>
                <Grid container rowSpacing={1} columnSpacing={1} justifyContent='start'>
                  {adminGames.map((game) => (
                    <GameSelectionAdminGame key={`ap_${game.id}`} game={game} onUpdatedGames={() => apiGetAdminGames.request()} />
                  ))}
                </Grid>
              </>
            )}

            <Divider />
            <Typography variant='h6' color='text.secondary'>
              Other games
            </Typography>
            <Grid container rowSpacing={1} columnSpacing={1}>
              {userGames && userGames.map((gp) => <GameSelectionUserGame key={`up_${gp.id}`} userGameParticipant={gp} />)}
            </Grid>
          </Stack>
        </>
      )}

      <Stack alignItems='center' mt={5}>
        {/* <Button
          variant='outlined'
          size='large'
          startIcon={React.createElement(PREDICTOR_ROUTE_COLLECTION.newGame.icon)}
          disabled
          // onClick={() => navigate(PREDICTOR_ROUTE_COLLECTION.newGame.path)}
        >
          Create
        </Button> */}
      </Stack>
    </Container>
  )
}

export default GameSelection
