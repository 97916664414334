import { Star } from '@mui/icons-material'
import {
  Box,
  Container,
  Divider,
  Hidden,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Theme,
  Tooltip,
  Typography,
  useMediaQuery,
} from '@mui/material'
import { UserAvatar } from 'components'
import { usePredictorContext } from 'context/PredictorContext'
import { usePredictorApi } from 'modules/api'
import { getGameStandings } from 'modules/api/requests'
import { GameStandings, GameStandingsEntry, GameStandingsPoint } from 'modules/api/types'
import { ActiveGamePageHeader, useActiveGame } from 'modules/games'
import { PREDICTOR_ROUTE_COLLECTION, STYLE_SETTINGS } from 'predictor-constants'
import { useCallback, useEffect, useMemo } from 'react'

const Standings = () => {
  const { user, activeParticipantId } = usePredictorContext()
  const uag = useActiveGame()
  const { game } = uag

  const isSmDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'))
  const isLgDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('lg'))

  const apiGetStandings = usePredictorApi<GameStandings>({ errorMessage: 'Could not get game standings.' })
  const gameStandings = apiGetStandings.data

  useEffect(() => {
    if (!game) return
    apiGetStandings.requestWithParams((client) => getGameStandings(client, game.id))
  }, [game])

  const stickySx = (theme: Theme) => ({
    position: 'sticky',
    left: 0,
    background: 'inherit',
    borderRight: `2px solid ${theme.palette.divider}`,
  })
  const leaderAmounts = useMemo((): { pointCode: string; pointAmount: number }[] => {
    if (!gameStandings) return []

    const amounts = gameStandings.pointDefinitions
      .map((pd) => ({
        pointCode: pd.pointCode,
        pointAmount: gameStandings.entries
          .flatMap((x) => x.points)
          .filter((x) => x.pointCode === pd.pointCode)
          .map((x) => x.pointAmount)
          .sort((a, b) => b - a)[0],
      }))
      .filter((x) => x.pointAmount !== 0)
    return amounts
  }, [gameStandings])

  const isLeadingAmount = useCallback(
    (point: GameStandingsPoint) => {
      return leaderAmounts.find((x) => x.pointCode === point.pointCode && x.pointAmount === point.pointAmount)
    },
    [leaderAmounts],
  )

  const isActiveParticipant = useCallback(
    (entry: GameStandingsEntry): boolean => {
      return (
        (activeParticipantId && activeParticipantId === entry.participant.id) ||
        (!activeParticipantId && Boolean(user?.gameParticipants.find((x) => x.id === entry.participant.id)))
      )
    },
    [activeParticipantId, user],
  )

  const stickyCell = (header?: boolean, entry?: GameStandingsEntry) => (
    <TableCell sx={stickySx} width={isSmDown ? `${190 + 16}px` : `${230 + 32}px`}>
      <Stack direction='row' alignItems='center' sx={{ gap: { xs: '8px', lg: '16px' }, pr: { xs: '8px', lg: '16px' } }}>
        <Box width='20px' textAlign='center' flexShrink={0}>
          {header && '#'}
          {!header && entry && entry.position}
        </Box>
        <Stack direction='row' alignItems='center' gap={1} flexGrow={1} width={isSmDown ? '100px' : '140px'} flexShrink={0}>
          {header && 'Participant'}
          {!header && entry && (
            <>
              <Hidden smDown>
                <UserAvatar size={30} user={{ name: entry.participant.userName, picture: entry.participant.picture }} />
              </Hidden>
              <Typography variant='body2' sx={isActiveParticipant(entry) ? { color: 'secondary.main', fontWeight: 'bold' } : {}}>
                {entry.participant.alias}
              </Typography>
            </>
          )}
        </Stack>
        <Box width='40px' textAlign='right' flexShrink={0}>
          <strong>
            <>
              {header && 'Points'}
              {!header && entry && entry.totalPoints}
            </>
          </strong>
        </Box>
      </Stack>
    </TableCell>
  )

  const headerRow = () => {
    if (!gameStandings) return <></>

    return (
      <TableHead>
        <TableRow sx={{ background: (theme) => theme.palette.emphasis }}>
          {stickyCell(true)}
          {gameStandings.pointDefinitions.map((pointDef) => (
            <TableCell key={pointDef.pointCode} align='right' width='43px'>
              <Tooltip
                componentsProps={{
                  tooltip: {
                    sx: {
                      bgcolor: (theme) => theme.palette.grey[800],
                      boxShadow: 3,
                    },
                  },
                }}
                title={
                  <Stack gap={0.5}>
                    <Typography variant='subtitle1'>{pointDef.pointName}</Typography>
                    <Typography variant='body2'>{pointDef.pointDescription}</Typography>
                  </Stack>
                }
              >
                <Box>{pointDef.pointCode === 'Winner' ? 'WN' : pointDef.pointCode}</Box>
              </Tooltip>
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    )
  }

  const row = (entry: GameStandingsEntry) => (
    <TableRow key={entry.participant.id} className={isActiveParticipant(entry) ? 'active-participant' : ''}>
      {stickyCell(false, entry)}
      {entry.points.map((point) => (
        <TableCell key={point.pointCode} align='right'>
          <Tooltip
            componentsProps={{
              tooltip: {
                sx: {
                  bgcolor: (theme) => theme.palette.grey[800],
                  boxShadow: 3,
                },
              },
            }}
            title={
              <Typography variant='body2' component='div'>
                <Stack gap={1}>
                  <Typography variant='subtitle1'>
                    {gameStandings?.pointDefinitions.find((x) => x.pointCode === point.pointCode)?.pointName}
                  </Typography>
                  <Divider />
                  <Stack direction='row' justifyContent='space-between' alignItems='center' gap={1}>
                    Amount: <strong>{point.pointAmount}</strong>
                  </Stack>
                  <Stack direction='row' justifyContent='space-between' alignItems='center' gap={1}>
                    Points: <strong>{point.totalPoints - point.pointLeaderBonus}</strong>
                  </Stack>
                  {point.pointLeaderBonus > 0 && (
                    <Stack direction='row' justifyContent='space-between' alignItems='center' gap={1}>
                      Leader bonus: <strong>{point.pointLeaderBonus}</strong>
                    </Stack>
                  )}
                </Stack>
              </Typography>
            }
          >
            <Stack direction='row' gap={0.5} justifyContent='end' alignItems='center'>
              {point.pointLeaderBonus > 0 && <Star sx={{ fontSize: 14, mb: '2px' }} color='primary' />}
              <Typography variant='body2' color={isLeadingAmount(point) ? 'primary' : 'initial'}>
                {isLeadingAmount(point) && <strong>{point.pointAmount}</strong>}
                {!isLeadingAmount(point) && point.pointAmount}
              </Typography>
            </Stack>
          </Tooltip>
        </TableCell>
      ))}
    </TableRow>
  )

  const tableWidth = useMemo(
    () => (isLgDown ? 'calc(100vw - 20px)' : `calc(vw - ${STYLE_SETTINGS.DRAWER_WIDTH} - ${STYLE_SETTINGS.RIGHT_SIDEBAR_WIDTH})`),
    [isLgDown],
  )

  return (
    <Container disableGutters={isLgDown} sx={{ display: 'flex', justifyContent: 'center' }}>
      <ActiveGamePageHeader useActiveGameState={uag} route={PREDICTOR_ROUTE_COLLECTION.standings} />
      {gameStandings && (
        <TableContainer
          sx={{
            maxWidth: `${tableWidth}`,
            boxShadow: 1,
          }}
        >
          <Table
            sx={{
              tableLayout: 'fixed',
              minWidth: tableWidth,
              'td,th': { px: { xs: '8px', lg: '16px' } },
              'tbody > tr': {
                '&:nth-of-type(odd)': {
                  backgroundColor: 'focus',
                },
                '&:nth-of-type(even)': {
                  backgroundColor: (theme) => theme.palette.background.default,
                },
              },
            }}
          >
            {headerRow()}
            <TableBody>{gameStandings.entries.map(row)}</TableBody>
          </Table>
        </TableContainer>
      )}
    </Container>
  )
}

export default Standings
