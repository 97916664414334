import { TournamentStage } from 'modules/api/types'

export enum FixturesFilterGroupBy {
  Group = 'Group',
  Stage = 'Stage',
}

export interface FixturesFilter {
  groupBy: FixturesFilterGroupBy
  group: string
  stage: TournamentStage
}
