import { FormControl, InputLabel, MenuItem, Select } from '@mui/material'
import { Tournament } from 'modules/api/types'
import React from 'react'
import { FormFieldProps } from 'types/form'

interface IProps extends FormFieldProps {
  tournaments: Tournament[]
}

const TournamentSelect: React.FC<IProps> = ({ field, onChange, tournaments }) => {
  return (
    <FormControl error={!field.isValid}>
      <InputLabel id='tournament-label'>Tournament</InputLabel>
      {tournaments && (
        <Select
          labelId='tournament-label'
          id='tournament'
          value={field.value}
          label='Tournament'
          onChange={(e) => onChange(e.target.value)}
        >
          {tournaments.map((tournament) => (
            <MenuItem key={tournament.id} value={tournament.id}>
              {tournament.name}
            </MenuItem>
          ))}
        </Select>
      )}
    </FormControl>
  )
}

export default TournamentSelect
