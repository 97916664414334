import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { Auth0Provider } from '@auth0/auth0-react'
import { PredictorContextProvider } from 'context/PredictorContext'
import { BrowserRouter } from 'react-router-dom'
import { SnackbarKey, SnackbarProvider } from 'notistack'
import { Button } from '@mui/material'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

const notistackRef = React.createRef<SnackbarProvider>()
const onClickDismiss = (key: SnackbarKey) => () => {
  notistackRef.current?.closeSnackbar(key)
}

const snackbarDismissAction = (key: SnackbarKey): React.ReactElement => (
  <Button onClick={onClickDismiss(key)} sx={{ color: 'white' }}>
    Dismiss
  </Button>
)

root.render(
  <Auth0Provider
    domain='predictor.eu.auth0.com'
    clientId='qzSYjhn6xBcBL8VLxhvxQv8XZhTm4aLa'
    redirectUri={`${window.location.origin}/callback`}
    audience='https://api.predictor.unclebenportal.net'
    useRefreshTokens={true}
    cacheLocation='localstorage'
    // scope='offline_access'
  >
    <SnackbarProvider ref={notistackRef} maxSnack={3} action={snackbarDismissAction}>
      <PredictorContextProvider>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </PredictorContextProvider>
    </SnackbarProvider>
  </Auth0Provider>,
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
