import { useAuth0 } from '@auth0/auth0-react'
import { usePredictorApi } from 'modules/api'
import React, { PropsWithChildren, useContext, useEffect, useMemo, useRef, useState } from 'react'
import { ThemeMode } from 'types/context/predictor-context'
import { IPredictorContextState } from '../types/context'
import { ParticipantStatus, PredictorUser } from '../modules/api/types'
import { getCurrentParticipantStatus, login, setUserSettings } from 'modules/api/requests'
import { isSuccessStatusCode } from 'utils/request-utils'

const stub = (): never => {
  throw new Error('You forgot to wrap your component in <PredictorContext>.')
}

const initialState: IPredictorContextState = {
  themeMode: 'light',
  rightSidebarOpen: false,
  setRightSidebarOpen: stub,
  setThemeMode: stub,
  setActiveGameId: stub,
  refreshUser: stub,
  setActiveParticipantId: stub,
  refreshParticipantStatus: stub,
}

const PredictorContext = React.createContext<IPredictorContextState>(initialState)

const PredictorContextProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const { user: authenticatedUser } = useAuth0()
  const [user, setUser] = useState(initialState.user)
  const [userError, setUserError] = useState<string>()

  const headerTitleContainerRef = useRef<Element>()
  const mainContainerRef = useRef<HTMLElement>()
  const mainContentRef = useRef<HTMLElement>()

  const rightSidebarTitleRef = useRef<HTMLElement>()
  const rightSidebarContentRef = useRef<HTMLElement>()
  const [rightSidebarOpen, setRightSidebarOpen] = React.useState(false)

  const [themeMode, setThemeModeLocal] = useState(initialState.themeMode)
  const [userSynced, setUserSynced] = useState(false)
  const apiLogin = usePredictorApi({ apiFunc: login })
  const apiSetUserSettings = usePredictorApi({ errorMessage: 'Error setting user settings.' })
  const [activeParticipantId, setActiveParticipantId] = useState<string>()

  const apiGetParticipantStatus = usePredictorApi<ParticipantStatus>()
  const [participantStatus, setParticipantStatus] = useState<ParticipantStatus>()

  useEffect(() => {
    if (apiLogin.data !== undefined) {
      const u = apiLogin.data as PredictorUser
      setUser(u)
      if (u.attributes.theme) setThemeModeLocal(u.attributes.theme as ThemeMode)
    }
  }, [apiLogin.data])

  useEffect(() => {
    if (apiLogin.error !== undefined) {
      setUserError(apiLogin.error)
      setUserSynced(false)
    }
  }, [apiLogin.error])

  useEffect(() => {
    if (!authenticatedUser) {
      if (user !== undefined) setUser(undefined)
      return
    }

    if (user && user.email === authenticatedUser.email) return

    if (!userSynced) {
      setUserError(undefined)
      apiLogin.request()
      setUserSynced(true)
    }
  }, [authenticatedUser])

  useEffect(() => {
    if (!apiGetParticipantStatus.data) return
    setParticipantStatus(apiGetParticipantStatus.data)
  }, [apiGetParticipantStatus.data])

  const setThemeMode = async (themeModeInput: ThemeMode): Promise<void> => {
    if (themeMode !== themeModeInput) {
      setThemeModeLocal(themeModeInput)
      apiSetUserSettings.requestWithParams((client) => setUserSettings(client, { attributes: { theme: themeModeInput } }))
    }
  }

  const setActiveGameId = async (gameId: string): Promise<void> => {
    if (gameId === user?.activeGameId) return

    const response = await apiSetUserSettings.requestWithParams((client) => setUserSettings(client, { activeGame: gameId }), {
      successMessage: 'Active game changed',
    })
    if (isSuccessStatusCode(response)) {
      setUser(response?.data)
    }
  }

  const refreshUser = async () => {
    await apiLogin.request()
  }

  useEffect(() => {
    if (!user) return
    refreshParticipantStatus()
  }, [user])

  const refreshParticipantStatus = async () => {
    if (!user) return

    if (!user.activeGameId) return

    // Cannot be null. There is a null check.
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    await apiGetParticipantStatus.requestWithParams((client) => getCurrentParticipantStatus(client, user.activeGameId!))
  }

  const ctx: IPredictorContextState = useMemo(
    () => ({
      user,
      userError,
      themeMode,
      headerTitleContainerRef,
      mainContainerRef,
      mainContentRef,
      rightSidebarTitleRef,
      rightSidebarContentRef,
      rightSidebarOpen,
      setRightSidebarOpen,
      activeParticipantId,
      setThemeMode,
      setActiveGameId,
      refreshUser,
      setActiveParticipantId,
      participantStatus,
      refreshParticipantStatus,
    }),
    [
      user,
      userError,
      themeMode,
      headerTitleContainerRef,
      mainContainerRef,
      mainContentRef,
      rightSidebarTitleRef,
      rightSidebarContentRef,
      rightSidebarOpen,
      activeParticipantId,
      participantStatus,
    ],
  )

  return <PredictorContext.Provider value={ctx}>{children}</PredictorContext.Provider>
}

const usePredictorContext = () => useContext(PredictorContext)

export { usePredictorContext, PredictorContextProvider }
