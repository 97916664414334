import { Close } from '@mui/icons-material'
import { Button, Divider, Paper, Portal, Stack, Table, TableBody, TableCell, TableHead, TableRow, Tooltip, Typography } from '@mui/material'
import { LoadingBox, UserAvatar } from 'components'
import { usePredictorContext } from 'context/PredictorContext'
import { usePredictorApi } from 'modules/api'
import { getAllFixturePredictions } from 'modules/api/requests'
import { Fixture, Game, Goal, GoalTeamType, ParticipantFixturePrediction } from 'modules/api/types'
import React, { useCallback, useEffect } from 'react'
import { getStageDisplayName } from 'utils/stage-utils'
import FixtureScore from './FixtureScore'
import FixtureStatus from './FixtureStatus'

interface IProps {
  game: Game
  fixture: Fixture
}

const SidebarFixtureDetails: React.FC<IProps> = ({ game, fixture }) => {
  const { rightSidebarContentRef } = usePredictorContext()
  const { rightSidebarOpen, setRightSidebarOpen } = usePredictorContext()

  const apiGetParticipantPredictions = usePredictorApi<ParticipantFixturePrediction[]>()
  const participantPredictions = apiGetParticipantPredictions.data?.sort(
    (a, b) =>
      b.fixturePrediction.outcomePointsAcquired +
      b.fixturePrediction.scorerPointsAcquired -
      (a.fixturePrediction.outcomePointsAcquired + a.fixturePrediction.scorerPointsAcquired),
  )

  const getTeamGoals = useCallback(
    (teamType: GoalTeamType): Goal[] => {
      return fixture.goals.filter((x) => x.team === teamType).sort((a, b) => a.time.localeCompare(b.time))
    },
    [fixture],
  )

  useEffect(() => {
    if (!game || !fixture) return
    apiGetParticipantPredictions.requestWithParams((client) => getAllFixturePredictions(client, game.id, fixture.id))
  }, [game, fixture])

  const renderGoal = useCallback(
    (teamType: GoalTeamType, goal: Goal, forTooltip: boolean) => (
      <Stack key={`${goal.team}-${goal.time}`} direction='row' alignItems='center' gap={1}>
        {teamType === 'Away' && <Typography variant='body2'>{goal.time}&apos;</Typography>}
        <Typography
          variant='body2'
          sx={{ maxWidth: forTooltip ? undefined : '100px', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}
        >
          {goal.scorerName} {(goal.type === 'PenaltyInNormalTime' || goal.type === 'PenaltyInExtraTime') && '(p)'}
        </Typography>
        {teamType === 'Home' && <Typography variant='body2'>{goal.time}&apos;</Typography>}
      </Stack>
    ),
    [],
  )

  const teamScorers = useCallback(
    (teamType: GoalTeamType): React.ReactElement => {
      const goals = getTeamGoals(teamType)

      return (
        <Stack gap={0.5} flexBasis='130px' flexGrow={0} flexShrink={0} alignItems={teamType === 'Home' ? 'end' : 'start'}>
          {goals.map((goal) => (
            <Tooltip key={`${goal.team}-${goal.time}`} title={renderGoal(teamType, goal, true)}>
              {renderGoal(teamType, goal, false)}
            </Tooltip>
          ))}
        </Stack>
      )
    },
    [fixture],
  )

  const scorers = () => {
    return (
      <>
        <Stack direction='row' justifyContent='space-between' gap={5}>
          {teamScorers('Home')}
          {teamScorers('Away')}
        </Stack>
      </>
    )
  }

  const predictionsTable = () => {
    if (!participantPredictions || participantPredictions.length === 0) return <></>

    return (
      <Table
        size='small'
        sx={{
          'th,td': { px: '2px' },
          'tr>th:first-of-type, tr>td:first-of-type': { pl: 1 },
          'tr>th:last-of-type, tr>td:last-of-type': { pr: 1 },
          'tr:nth-of-type(even):not(:hover)': { backgroundColor: 'focus' },
          tr: { cursor: 'pointer' },
        }}
      >
        <TableHead sx={{ backgroundColor: 'emphasis' }}>
          <TableRow>
            <TableCell>Participant</TableCell>
            <TableCell align='center'>Score</TableCell>
            <TableCell align='center'>GLS</TableCell>
            <TableCell align='right'>PTS</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {participantPredictions.map((pp) => (
            <Tooltip
              key={pp.participant.id}
              componentsProps={{
                tooltip: {
                  sx: {
                    bgcolor: (theme) => theme.palette.grey[800],
                    boxShadow: 3,
                  },
                },
              }}
              title={
                <Typography variant='body2' component='div' width='270px'>
                  <Stack gap={1} width='260px'>
                    <Stack direction='row' alignItems='center' gap={1}>
                      <UserAvatar
                        size={30}
                        user={{ name: pp.participant.gameAlias, picture: pp.participant.picture }}
                        showTooltip={false}
                      />
                      <Stack>
                        <Typography variant='subtitle1'>{pp.participant.gameAlias}</Typography>
                        {pp.participant.userName && <Typography variant='caption'>{pp.participant.userName}</Typography>}
                      </Stack>
                    </Stack>
                    <Divider />
                    <Stack direction='row' justifyContent='space-between' alignItems='center' gap={1}>
                      Outcome: <strong>{pp.fixturePrediction.predictionOutcome}</strong>
                    </Stack>
                    <Stack direction='row' justifyContent='space-between' alignItems='center' gap={1}>
                      Outcome points: <strong>{pp.fixturePrediction.outcomePointsAcquired}</strong>
                    </Stack>
                    <Divider />
                    <Stack direction='row' justifyContent='space-between' alignItems='center' gap={1}>
                      Scorer goals: <strong>{pp.fixturePrediction.scorerNormalGoals}</strong>
                    </Stack>
                    <Stack direction='row' justifyContent='space-between' alignItems='center' gap={1}>
                      Scorer penalty goals: <strong>{pp.fixturePrediction.scorerPenaltyGoals}</strong>
                    </Stack>
                    <Stack direction='row' justifyContent='space-between' alignItems='center' gap={1}>
                      Scorer points: <strong>{pp.fixturePrediction.scorerPointsAcquired}</strong>
                    </Stack>
                    <Divider />
                    <Stack direction='row' justifyContent='space-between' alignItems='center' gap={1}>
                      Total points:{' '}
                      <strong>{pp.fixturePrediction.scorerPointsAcquired + pp.fixturePrediction.outcomePointsAcquired}</strong>
                    </Stack>
                  </Stack>
                </Typography>
              }
            >
              <TableRow sx={{ '&:hover': { backgroundColor: 'emphasis' } }}>
                <TableCell sx={{ maxWidth: '120px', whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden' }}>
                  <Stack direction='row' alignItems='center' gap={1}>
                    <UserAvatar
                      size='small'
                      user={{ name: pp.participant.gameAlias, picture: pp.participant.picture }}
                      showTooltip={false}
                    />
                    <Typography variant='body2'>{pp.participant.gameAlias}</Typography>
                  </Stack>
                </TableCell>
                <TableCell
                  align='center'
                  sx={{
                    color:
                      pp.fixturePrediction.predictionOutcome === 'ES'
                        ? 'success.main'
                        : pp.fixturePrediction.predictionOutcome === 'CO'
                        ? 'secondary.main'
                        : 'error.main',
                  }}
                >{`${pp.fixturePrediction.homeTeamScore ?? ''} - ${pp.fixturePrediction.awayTeamScore ?? ''}`}</TableCell>
                <TableCell align='center'>
                  {pp.fixturePrediction.scorerNormalGoals}
                  {pp.fixturePrediction.scorerPenaltyGoals > 0 && ` (${pp.fixturePrediction.scorerPenaltyGoals})`}
                </TableCell>
                <TableCell align='right'>
                  <strong>{pp.fixturePrediction.outcomePointsAcquired + pp.fixturePrediction.scorerPointsAcquired}</strong>
                </TableCell>
              </TableRow>
            </Tooltip>
          ))}
        </TableBody>
      </Table>
    )
  }

  return (
    <>
      {rightSidebarContentRef?.current && rightSidebarOpen && (
        <Portal container={rightSidebarContentRef.current}>
          <Paper sx={{ m: 1, py: 1 }}>
            <Typography variant='h6' textAlign='center'>
              {getStageDisplayName(fixture.code)} fixture
            </Typography>
            <Stack gap={2} pt={2}>
              <Stack gap={1} px={2}>
                <FixtureStatus fixture={fixture} />
                <FixtureScore game={game} fixture={fixture} size='small' showOutcomeDescription />
                {scorers()}
              </Stack>
              <Stack>
                {apiGetParticipantPredictions.isLoading && <LoadingBox />}
                {!apiGetParticipantPredictions.isLoading && participantPredictions && predictionsTable()}
              </Stack>
              <Button
                sx={{ width: '100px', alignSelf: 'center' }}
                variant='outlined'
                endIcon={<Close />}
                onClick={() => setRightSidebarOpen(false)}
              >
                Close
              </Button>
            </Stack>
          </Paper>
        </Portal>
      )}
    </>
  )
}

export default SidebarFixtureDetails
