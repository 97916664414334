import { Done, Error, OpenInNew } from '@mui/icons-material'
import { Box, Chip, Fade, Paper, Stack, Tooltip } from '@mui/material'
import { LoadingBox } from 'components'
import { usePredictorContext } from 'context/PredictorContext'
import { usePredictorApi } from 'modules/api'
import { setFixturePrediction } from 'modules/api/requests'
import { Fixture as FixtureType, FixturePrediction, Game } from 'modules/api/types'
import React, { useCallback, useEffect } from 'react'
import { getStageDisplayName } from 'utils/stage-utils'
import FixturePredictionStatus from './FixturePredictionStatus'
import FixtureScore from './FixtureScore'
import FixtureStatus from './FixtureStatus'

interface IProps {
  game: Game
  fixture: FixtureType
  selected: boolean
  onSelected: () => void
}

const Fixture: React.FC<IProps> = ({ game, fixture, selected, onSelected }) => {
  const { refreshParticipantStatus } = usePredictorContext()

  const apiSetFixturePrediction = usePredictorApi<FixturePrediction>({ errorMessage: 'Could not update prediction. {{error}}' })

  const onScoreInputChanged = (homeScore: number, awayScore: number) => {
    apiSetFixturePrediction.requestWithParams((client) => setFixturePrediction(client, game.id, fixture.id, { homeScore, awayScore }))
  }

  const isFixtureLocked = useCallback(() => {
    return game.predictionRules.lockedFixtures.includes(fixture.id)
  }, [game, fixture])

  useEffect(() => {
    if (!apiSetFixturePrediction.data) return
    refreshParticipantStatus()
  }, [apiSetFixturePrediction.data])

  const row1 = (
    <Stack direction='row' justifyContent='space-between' alignItems='center' px={1} pt={0.5}>
      <Box justifySelf='start' width='100px' flexGrow={1}>
        <Chip size='small' sx={{ borderRadius: 0, backgroundColor: 'emphasis' }} label={getStageDisplayName(fixture.code)} />
      </Box>
      <Stack flexShrink={0}>
        <FixtureStatus fixture={fixture} />
      </Stack>
      <Stack width='100px' flexGrow={1} alignItems='end' pr={1}>
        <>
          {apiSetFixturePrediction.isLoading && <LoadingBox size='small' />}
          {!apiSetFixturePrediction.isLoading && apiSetFixturePrediction.error && (
            <Fade in={true}>
              <Tooltip title={apiSetFixturePrediction.error}>
                <Error color='error' />
              </Tooltip>
            </Fade>
          )}
          {!apiSetFixturePrediction.isLoading && !apiSetFixturePrediction.error && apiSetFixturePrediction.data && (
            <Fade in={true}>
              <Tooltip
                title={`Prediction updated successfully to ${apiSetFixturePrediction.data.homeTeamScore} - ${apiSetFixturePrediction.data.awayTeamScore}`}
              >
                <Done color='success' />
              </Tooltip>
            </Fade>
          )}
          {isFixtureLocked() && (
            <>
              <OpenInNew fontSize='small' sx={{ color: (theme) => (selected ? 'secondary.main' : theme.palette.grey[400]) }} />
            </>
          )}
        </>
      </Stack>
    </Stack>
  )

  const row2 = (
    <Stack direction='row' justifyContent='center' px={1}>
      <FixtureScore game={game} fixture={fixture} onScoreInputChanged={onScoreInputChanged} showOutcomeDescription />
    </Stack>
  )

  const row3 = (
    <Stack direction='row' justifyContent='center'>
      <FixturePredictionStatus fixture={fixture} game={game} />
    </Stack>
  )

  return (
    <Paper square>
      <Stack
        gap={0.5}
        sx={{
          cursor: isFixtureLocked() ? 'pointer' : 'initial',
          boxShadow: selected ? 3 : 0,
          // border: selected ? 'solid 1px' : 'none',
          // borderColor: selected ? 'secondary.main' : 'initial',
        }}
        onClick={() => isFixtureLocked() && onSelected()}
      >
        {row1}
        {row2}
        {row3}
      </Stack>
    </Paper>
  )
}

export default Fixture
