import { Casino, DriveFileRenameOutline, ManageAccounts, Person, PersonOff, Save, Visibility } from '@mui/icons-material'
import { IconButton, Stack, SvgIconTypeMap, Switch, TableCell, TableRow, TextField, Typography } from '@mui/material'
import { OverridableComponent } from '@mui/material/OverridableComponent'
import { UserAvatar } from 'components'
import { usePredictorContext } from 'context/PredictorContext'
import { usePredictorApi } from 'modules/api'
import { patchParticipant } from 'modules/api/requests'
import { Game, GameParticipant, GameParticipantPatchRequest, GameParticipantWithStatus } from 'modules/api/types'
import React, { FormEvent, useEffect, useMemo, useState } from 'react'
import { isSuccessStatusCode } from 'utils/request-utils'
import { ExtendedPredictionsStatus } from '../types'
import { getExtendedPredictionsStatus } from '../utils'
import GameParticipantStatus from './GameParticipantStatus'

interface IProps {
  game: Game
  participantWithStatus: GameParticipantWithStatus
  onChange: () => void
}

const GameParticipantRow: React.FC<IProps> = ({ game, participantWithStatus, onChange }) => {
  const { participant, status } = participantWithStatus
  const { user } = usePredictorContext()
  const apiPatchParticipant = usePredictorApi({ successMessage: 'Participant updated' })

  const [newAlias, setNewAlias] = useState<string>()

  const extendedPredictionsStatus = useMemo((): ExtendedPredictionsStatus | undefined => {
    if (!game || !status) return undefined
    return getExtendedPredictionsStatus(status.predictionsStatus, game)
  }, [game, status])

  const updateParticipant = async (participant: GameParticipant, change: Partial<GameParticipantPatchRequest>) => {
    const { gameAlias, isDisabled, isViewOnly, isGameAdmin } = participant
    const request: GameParticipantPatchRequest = { ...{ gameAlias, isDisabled, isViewOnly, isGameAdmin }, ...change }
    const response = await apiPatchParticipant.requestWithParams((client) => patchParticipant(client, participant.id, request))
    if (isSuccessStatusCode(response)) onChange()
  }

  const onEnabledChanged = (participant: GameParticipant, isEnabled: boolean) => updateParticipant(participant, { isDisabled: !isEnabled })

  const onCanPlayChanged = (participant: GameParticipant, canPlay: boolean) => updateParticipant(participant, { isViewOnly: !canPlay })

  const onIsAdminChange = (participant: GameParticipant, isAdmin: boolean) => updateParticipant(participant, { isGameAdmin: isAdmin })

  const onEditAliasClick = () => {
    if (newAlias === undefined) {
      // Enter edit mode
      setNewAlias(participant.gameAlias)
      return
    }

    // Save new alias if valid
    if (newAlias === '') return

    updateParticipant(participant, { gameAlias: newAlias })
  }

  const onEditAliasSubmit = (e: FormEvent) => {
    e.preventDefault()
    onEditAliasClick()
  }

  const inEditAliasMode = () => newAlias !== undefined
  const isCurrentUserRow = (): boolean => user !== undefined && user.email === participant.user.email

  useEffect(() => {
    setNewAlias(undefined)
  }, [participant])

  const participantSwitch = (
    participant: GameParticipant,
    checked: boolean,
    offIcon: OverridableComponent<SvgIconTypeMap>,
    onIcon: OverridableComponent<SvgIconTypeMap>,
    onChange: (participant: GameParticipant, checked: boolean) => void,
    disabled: boolean,
  ): React.ReactElement => (
    <Stack direction='row' alignItems='center' justifyContent='center'>
      {React.createElement(offIcon, { color: !checked ? 'error' : 'disabled' })}
      <Switch checked={checked} onChange={(e) => onChange(participant, e.target.checked)} disabled={disabled} />
      {React.createElement(onIcon, { color: checked ? 'primary' : 'inherit' })}
    </Stack>
  )

  return (
    <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
      <TableCell component='th' scope='row'>
        <Stack direction='row' alignItems='center' gap={1}>
          <UserAvatar user={participant.user} />
          <Stack>
            <form onSubmit={onEditAliasSubmit}>
              <Stack direction='row' alignItems='center' gap={0.5}>
                {!inEditAliasMode() && <Typography>{participant.gameAlias}</Typography>}
                {inEditAliasMode() && <TextField size='small' value={newAlias} onChange={(e) => setNewAlias(e.target.value)} />}
                {!inEditAliasMode() && (
                  <IconButton size='small' onClick={onEditAliasClick}>
                    <DriveFileRenameOutline fontSize='small' />
                  </IconButton>
                )}
                {inEditAliasMode() && (
                  <IconButton size='small' type='submit'>
                    <Save color='primary' />
                  </IconButton>
                )}
              </Stack>
            </form>
            <Typography variant='caption'>{participant.user.email}</Typography>
          </Stack>
        </Stack>
      </TableCell>
      <TableCell align='center'>{extendedPredictionsStatus && <GameParticipantStatus status={extendedPredictionsStatus} />}</TableCell>
      <TableCell align='center'>
        {participantSwitch(participant, !participant.isDisabled, PersonOff, Person, onEnabledChanged, isCurrentUserRow())}
      </TableCell>
      <TableCell align='center'>
        {participantSwitch(participant, !participant.isViewOnly, Visibility, Casino, onCanPlayChanged, false)}
      </TableCell>
      <TableCell align='center'>
        {participantSwitch(participant, participant.isGameAdmin, Person, ManageAccounts, onIsAdminChange, isCurrentUserRow())}
      </TableCell>
    </TableRow>
  )
}

export default GameParticipantRow
