import { Game, PredictionsStatus } from 'modules/api/types'
import { ExtendedPredictionsStatus } from './types'

const getExtendedPredictionsStatus = (predictionsStatus: PredictionsStatus, activeGame: Game): ExtendedPredictionsStatus => {
  const extendedStatus: ExtendedPredictionsStatus = {
    ...predictionsStatus,
    incompleteUnlockedFixturePredictions: predictionsStatus.incompleteFixturePredictions.filter(
      (x) => !activeGame.predictionRules.lockedFixtures.includes(x),
    ),
    missingUnlockedStagePredictions: activeGame.predictionRules.teamPredictionsLocked ? [] : predictionsStatus.missingStagePredictions,
    missingUnlockedScorerPredictions: activeGame.predictionRules.scorerPredictionsLocked ? 0 : predictionsStatus.missingScorerPredictions,
    isMissingUnlockedFixturePredictions: false,
    isMissingUnlockedStagePredictions: false,
    isMissingUnlockedScorerPredictions: false,
    isMissingUnlockedPredictions: false,
  }

  extendedStatus.isMissingUnlockedFixturePredictions = extendedStatus.incompleteUnlockedFixturePredictions.length > 0
  extendedStatus.isMissingUnlockedStagePredictions = extendedStatus.missingUnlockedStagePredictions.length > 0
  extendedStatus.isMissingUnlockedScorerPredictions = extendedStatus.missingUnlockedScorerPredictions > 0
  extendedStatus.isMissingUnlockedPredictions =
    extendedStatus.isMissingUnlockedFixturePredictions ||
    extendedStatus.isMissingUnlockedStagePredictions ||
    extendedStatus.isMissingUnlockedScorerPredictions

  return extendedStatus
}

export { getExtendedPredictionsStatus }
