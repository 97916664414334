import { Save } from '@mui/icons-material'
import { Button, Container, Stack } from '@mui/material'
import { PageNotFound, PageTitle } from 'components'
import { usePredictorApi } from 'modules/api'
import { getGame, getTournamentGameConfigurationOptions, updateGamePointDefinitions } from 'modules/api/requests'
import { Game, GameConfigurationGamePoint, GameConfigurationOptions } from 'modules/api/types'
import { useConfirmationModalContext } from 'modules/ui'
import { PREDICTOR_ROUTE_COLLECTION } from 'predictor-constants'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { isSuccessStatusCode } from 'utils/request-utils'
import { GamePointsForm } from '../components'

const EditGamePoints = () => {
  const { gameId } = useParams()
  const { showConfirm } = useConfirmationModalContext()

  if (!gameId) return <PageNotFound />

  const apiGetGame = usePredictorApi<Game>({ apiFunc: (client) => getGame(client, gameId) })
  const game = apiGetGame.data

  const apiGetConfigOptions = usePredictorApi<GameConfigurationOptions>()
  const gameConfigOptions = apiGetConfigOptions.data

  const [configurationPoints, setConfigurationPoints] = useState<GameConfigurationGamePoint[]>()

  const apiUpdateGamePointDefinitions = usePredictorApi({ successMessage: 'Points updated.' })

  useEffect(() => {
    if (!game) return
    apiGetConfigOptions.requestWithParams((client) => getTournamentGameConfigurationOptions(client, game.tournamentId))
  }, [game])

  useEffect(() => {
    if (!gameConfigOptions || !game) return
    // Am filtering out undefined.

    const configPoints: (GameConfigurationGamePoint | undefined)[] = gameConfigOptions.gamePoints
      .map((x) => {
        const matchingGamePointDef = game.pointDefinitions.find((pd) => pd.pointType === x.code)
        if (!matchingGamePointDef) return undefined
        x.isEnabled = matchingGamePointDef.isEnabled
        x.pointValue = matchingGamePointDef.pointValue
        x.bonusForPointLeader = matchingGamePointDef.bonusForPointLeader
        return x
      })
      .filter((x) => x !== undefined)

    setConfigurationPoints(configPoints as GameConfigurationGamePoint[])
  }, [gameConfigOptions])

  const saveGamePointDefinitions = async () => {
    if (!configurationPoints || !game) return

    const confirmed = await showConfirm({
      title: 'Update game points',
      message: 'You are about to change the points of this game. Make sure this is communicated clearly to all participants of your game.',
      confirmText: 'Confirm change',
    })

    if (!confirmed) return

    const response = await apiUpdateGamePointDefinitions.requestWithParams((client) =>
      updateGamePointDefinitions(client, gameId, configurationPoints),
    )

    if (isSuccessStatusCode(response)) apiGetGame.request()
  }

  return (
    <Container maxWidth='md' disableGutters>
      <PageTitle title={PREDICTOR_ROUTE_COLLECTION.editGamePoints.title} icon={PREDICTOR_ROUTE_COLLECTION.editGamePoints.icon} />
      {configurationPoints && (
        <Stack gap={2} alignItems='center'>
          <GamePointsForm configurationPoints={configurationPoints} onChange={(newPoints) => setConfigurationPoints(newPoints)} />
          <Button variant='contained' sx={{ width: '250px' }} endIcon={<Save />} onClick={saveGamePointDefinitions}>
            Save points
          </Button>
        </Stack>
      )}
    </Container>
  )
}

export default EditGamePoints
