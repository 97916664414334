import { CheckBox, CheckBoxOutlineBlank, Preview } from '@mui/icons-material'
import { Avatar, Button, Card, CardActions, CardContent, Divider, Grid, Stack, Tooltip, Typography } from '@mui/material'
import { usePredictorContext } from 'context/PredictorContext'
import { UserGameParticipant } from 'modules/api/types'
import React from 'react'

interface IProps {
  userGameParticipant: UserGameParticipant
}

const GameSelectionUserGame: React.FC<IProps> = ({ userGameParticipant }) => {
  const { user, activeParticipantId, setActiveParticipantId, setActiveGameId } = usePredictorContext()

  const setActiveGame = (ugp: UserGameParticipant) => {
    if (user?.activeGameId !== ugp.gameId) setActiveGameId(ugp.gameId)
    setActiveParticipantId(ugp.id)
  }

  if (!user) return <></>

  return (
    <Grid item xs={12} md={4}>
      <Card>
        <CardContent>
          <Stack gap={2}>
            <Stack direction='row' justifyContent='between' alignItems='start'>
              <Stack flexGrow={1}>
                <Typography variant='subtitle2' color='text.secondary'>
                  {userGameParticipant.tournamentName}
                </Typography>
                <Typography variant='h6' color={userGameParticipant.gameId === user.activeGameId ? 'secondary.main' : 'inherit'}>
                  {userGameParticipant.gameName}
                </Typography>
                <Typography variant='body2' color='gray'>
                  {userGameParticipant.gameAlias}
                </Typography>
              </Stack>
              <Stack direction='row' gap={1}>
                {userGameParticipant.isViewOnly && (
                  <Tooltip title='View only'>
                    <Avatar sx={{ width: 30, height: 30, bgcolor: (theme) => theme.palette.emphasis }}>
                      <Preview fontSize='small' />
                    </Avatar>
                  </Tooltip>
                )}
              </Stack>
            </Stack>
          </Stack>
        </CardContent>
        <Divider />
        <CardActions sx={{ alignItems: 'center', justifyContent: 'space-between' }}>
          <Button
            size='small'
            disabled={userGameParticipant.gameId === user.activeGameId && activeParticipantId === userGameParticipant.id}
            startIcon={
              userGameParticipant.gameId === user.activeGameId && activeParticipantId === userGameParticipant.id ? (
                <CheckBox color='secondary' />
              ) : userGameParticipant.gameId === user.activeGameId ? (
                <CheckBox />
              ) : (
                <CheckBoxOutlineBlank />
              )
            }
            onClick={() => setActiveGame(userGameParticipant)}
          >
            {userGameParticipant.gameId === user.activeGameId && (!activeParticipantId || activeParticipantId === userGameParticipant.id)
              ? 'Active'
              : 'Make active'}
          </Button>
        </CardActions>
      </Card>
    </Grid>
  )
}

export default GameSelectionUserGame
