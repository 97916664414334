import { InfoRounded } from '@mui/icons-material'
import { Hidden, Stack, Table, TableBody, TableCell, TableHead, TableRow, Tooltip, Typography } from '@mui/material'
import { GameConfigurationGamePoint } from 'modules/api/types'
import React from 'react'

interface IProps {
  points: GameConfigurationGamePoint[]
}

const RulesPoints: React.FC<IProps> = ({ points }) => {
  return (
    <Table size='small'>
      <TableHead>
        <TableRow>
          <Hidden smDown>
            <TableCell width='200px'>Point name (Code)</TableCell>
          </Hidden>
          <Hidden smUp>
            <TableCell width='100px'>Point</TableCell>
          </Hidden>
          <Hidden mdDown>
            <TableCell>Description</TableCell>
          </Hidden>
          <TableCell width='50px' align='center'>
            Value
          </TableCell>
          <TableCell width='50px' align='center'>
            Leader
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {points.map((point) => (
          <TableRow key={point.code}>
            <Hidden smDown>
              <TableCell>
                {point.name} ({point.code})
              </TableCell>
            </Hidden>
            <Hidden smUp>
              <TableCell>
                <Stack direction='row' alignItems='center' gap={1}>
                  <Tooltip title={point.description}>
                    <InfoRounded fontSize='small' />
                  </Tooltip>
                  <Typography variant='body1'>
                    <strong>{point.code}</strong>
                  </Typography>
                </Stack>
              </TableCell>
            </Hidden>
            <Hidden mdDown>
              <TableCell>{point.description}</TableCell>
            </Hidden>
            <TableCell align='center'>{point.pointValue}</TableCell>
            <TableCell align='center'>{point.bonusForPointLeader}</TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>

    // <Stack>
    //   <Paper square sx={{ p: 1 }}>
    //     <Stack direction='row'>
    //       <Hidden smDown>
    //         <Typography variant='subtitle2' width='200px' flexShrink={0}>
    //           Point name (code)
    //         </Typography>
    //       </Hidden>
    //       <Hidden smUp>
    //         <Typography variant='subtitle2' width='80px' flexShrink={0}>
    //           Point
    //         </Typography>
    //       </Hidden>
    //       <Stack flexGrow={1}>
    //         <Hidden mdDown>
    //           <Typography variant='subtitle2'>Description</Typography>
    //         </Hidden>
    //       </Stack>
    //       <Typography variant='subtitle2' width='50px' textAlign='right' flexShrink={0}>
    //         Value
    //       </Typography>
    //       <Typography variant='subtitle2' width='50px' textAlign='right' flexShrink={0}>
    //         Leader
    //       </Typography>
    //     </Stack>
    //   </Paper>
    //   {gamePoints.map((point) => (
    //     <Paper variant='outlined' key={point.code} square sx={{ p: 1 }}>
    //       <Stack direction='row' gap={1} alignItems='center'>
    //         <Hidden smDown>
    //           <Typography width='200px' flexShrink={0}>
    //             {point.name} ({point.code})
    //           </Typography>
    //         </Hidden>
    //         <Hidden smUp>
    //           <Tooltip title={point.name}>
    //             <Typography width='80px' flexShrink={0}>
    //               {point.code}
    //             </Typography>
    //           </Tooltip>
    //         </Hidden>
    //         <Hidden mdUp>
    //           <Tooltip title={point.description}>
    //             <InfoRounded fontSize='small' />
    //           </Tooltip>
    //         </Hidden>
    //         <Hidden mdDown>
    //           <Stack flexGrow={1}>
    //             <Typography variant='body2'>{point.description}</Typography>
    //           </Stack>
    //         </Hidden>
    //         <Typography width='30px' textAlign='right' flexShrink={0}>
    //           {point.pointValue}
    //         </Typography>
    //         <Typography width='30px' textAlign='right' flexShrink={0}>
    //           {point.bonusForPointLeader}
    //         </Typography>
    //       </Stack>
    //     </Paper>
    //   ))}
    // </Stack>
  )
}

export default RulesPoints
