import { Circle, DateRange } from '@mui/icons-material'
import { Hidden, Stack, Theme, Typography, useMediaQuery } from '@mui/material'
import { Fixture } from 'modules/api/types'
import React from 'react'
import { format as formatDate } from 'date-fns'

interface IProps {
  fixture: Fixture
}
const FixtureStatus: React.FC<IProps> = ({ fixture }) => {
  const isSmDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'))

  const getFixtureStatusText = (): string => {
    switch (fixture.fixtureStatus) {
      case 'NotStarted':
        return formatDate(new Date(fixture.dateTime), 'dd MMM HH:mm')
      case 'InProgress':
        return 'In progress'
      case 'FirstHalf':
        return 'First half'
      case 'SecondHalf':
        return 'Second half'
      case 'ExtraTime':
        return 'Extra time'
      case 'Penalties':
        return 'Penalties'
      case 'FullTime':
        return 'Full time'
      default:
        return ''
    }
  }

  const renderStatusIcon = (): React.ReactElement => {
    switch (fixture.fixtureStatus) {
      case 'NotStarted':
        return <DateRange sx={{ fontSize: 12 }} />
      case 'FullTime':
        return <Circle sx={{ fontSize: 12 }} />
    }
    return <Circle sx={{ fontSize: 12 }} className='blink' color='primary' />
  }
  const renderStatusText = (): React.ReactElement => {
    switch (fixture.fixtureStatus) {
      case 'NotStarted':
      case 'FullTime':
        return <Typography variant='body2'>{getFixtureStatusText()}</Typography>
    }

    return (
      <Typography variant='body2' color='primary'>
        <strong>{getFixtureStatusText()}</strong>
      </Typography>
    )
  }

  return (
    <Stack
      px={1}
      py={0.5}
      minWidth={isSmDown ? '100px' : '200px'}
      direction='row'
      flexGrow={1}
      alignItems='center'
      justifyContent='center'
      sx={{
        // backgroundColor: 'emphasis',
        boxShadow: isSmDown ? 0 : 0,
      }}
      gap={1}
    >
      <Hidden smDown>
        <Stack width='20px' alignItems='end'>
          {renderStatusIcon()}
        </Stack>
      </Hidden>
      <Stack flexShrink={0} alignItems='center'>
        {renderStatusText()}
      </Stack>
      <Hidden smDown>
        <Stack width='20px'>{renderStatusIcon()}</Stack>
      </Hidden>
    </Stack>
  )
}

export default FixtureStatus
