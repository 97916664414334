import { FormControl, InputLabel, MenuItem, Paper, Select, Stack, Tooltip } from '@mui/material'
import { Fixture as FixtureType, TournamentStage } from 'modules/api/types'
import { useCallback, useMemo } from 'react'
import { getStage, getStageFromCode } from 'utils/stage-utils'
import { FixturesFilter as FixturesFilterType, FixturesFilterGroupBy } from '../types'
import _ from 'lodash'
import { getGroupsFromFixtures, isGroupMissingPredictions, isKnockoutStageMissingPredictions } from '../utils'
import { WarningRounded } from '@mui/icons-material'
import { ExtendedPredictionsStatus } from 'modules/games'

interface IProps {
  fixtures: FixtureType[]
  filter: FixturesFilterType
  onChange: (newFilter: FixturesFilterType) => void
  extendedPredictionsStatus?: ExtendedPredictionsStatus
}

const FixturesFilter: React.FC<IProps> = ({ fixtures, filter, onChange, extendedPredictionsStatus }: IProps) => {
  const groupOptions = useMemo(() => getGroupsFromFixtures(fixtures), [fixtures])

  const stageOptions: TournamentStage[] = useMemo(() => {
    const fixturesByStage = _.groupBy(
      fixtures.map((fixture) => ({ ...fixture, stage: getStageFromCode(fixture.code) })),
      'stage.key',
    )
    const stages = Object.values(TournamentStage).filter((s) => Object.keys(fixturesByStage).includes(s))
    return stages
  }, [fixtures])

  const renderGroupByFilter = () => (
    <FormControl sx={{ minWidth: '100px', maxWidth: '200px', flexGrow: 1, flexBasis: 0 }}>
      <InputLabel>Group By</InputLabel>
      <Select
        label='Group By'
        value={filter.groupBy}
        onChange={(e) => onChange({ ...filter, groupBy: FixturesFilterGroupBy[e.target.value as keyof typeof FixturesFilterGroupBy] })}
      >
        <MenuItem value={FixturesFilterGroupBy.Group}>Group</MenuItem>
        <MenuItem value={FixturesFilterGroupBy.Stage}>Stage</MenuItem>
      </Select>
    </FormControl>
  )

  const renderGroupName = useCallback(
    (group: string): React.ReactElement => (
      <Stack direction='row' gap={1} alignItems='center'>
        <span>{group}</span>
        {isGroupMissingPredictions(fixtures, extendedPredictionsStatus, group) && (
          <Tooltip title='Missing Predictions'>
            <WarningRounded color='warning' fontSize='small' />
          </Tooltip>
        )}
      </Stack>
    ),
    [fixtures, extendedPredictionsStatus],
  )

  const renderStageName = useCallback(
    (stage: TournamentStage): React.ReactElement => {
      let missingPredictions = false

      if (stage === TournamentStage.group) missingPredictions = isGroupMissingPredictions(fixtures, extendedPredictionsStatus, 'any')
      else missingPredictions = isKnockoutStageMissingPredictions(fixtures, extendedPredictionsStatus, stage)

      return (
        <Stack direction='row' gap={1} alignItems='center'>
          <span>{getStage(stage)?.displayName}</span>
          {missingPredictions && (
            <Tooltip title='Missing Predictions'>
              <WarningRounded color='warning' fontSize='small' />
            </Tooltip>
          )}
        </Stack>
      )
    },
    [fixtures, extendedPredictionsStatus],
  )

  const renderGroupFilter = () => (
    <FormControl sx={{ minWidth: '100px', maxWidth: '200px', flexGrow: 1, flexBasis: 0 }}>
      <InputLabel>Group</InputLabel>
      <Select
        label='Group'
        value={filter.group}
        onChange={(e) => onChange({ ...filter, group: e.target.value })}
        renderValue={renderGroupName}
      >
        {fixtures &&
          groupOptions.map((group) => (
            <MenuItem key={group} value={group} sx={{ display: 'flex', direction: 'row', alignItems: 'center', gap: 1 }}>
              {renderGroupName(group)}
            </MenuItem>
          ))}
      </Select>
    </FormControl>
  )

  const renderStageFilter = () => (
    <FormControl sx={{ minWidth: '100px', maxWidth: '200px', flexGrow: 1, flexBasis: 0 }}>
      <InputLabel>Stage</InputLabel>
      <Select
        label='Stage'
        value={filter.stage}
        onChange={(e) => onChange({ ...filter, stage: e.target.value as TournamentStage })}
        renderValue={renderStageName}
      >
        {stageOptions.map((stage) => (
          <MenuItem key={stage} value={stage}>
            {renderStageName(stage)}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )

  return (
    <Paper sx={{ p: 2, backgroundColor: 'focus' }} square>
      <Stack direction='row' gap={1} justifyContent='center'>
        {renderGroupByFilter()}
        {filter.groupBy === FixturesFilterGroupBy.Group && renderGroupFilter()}
        {filter.groupBy === FixturesFilterGroupBy.Stage && renderStageFilter()}
      </Stack>
    </Paper>
  )
}

export default FixturesFilter
