import { Save } from '@mui/icons-material'
import { Alert, AlertTitle, Button, Container, Stack, TextField } from '@mui/material'
import { usePredictorApi } from 'modules/api'
import { patchGameAlias } from 'modules/api/requests'
import { PREDICTOR_ROUTE_COLLECTION } from 'predictor-constants'
import React, { useEffect, useState } from 'react'
import { ActiveGamePageHeader } from '../components'
import useActiveGame from '../hooks/useActiveGame'

const ChangeGameAlias = () => {
  const uag = useActiveGame()
  const { game } = uag

  const apiSetGameAlias = usePredictorApi({ successMessage: 'Game alias changed.' })
  const [gameAlias, setGameAlias] = useState<string>('')

  useEffect(() => {
    if (!game) return

    setGameAlias(game.userParticipant.gameAlias)
  }, [game])

  return (
    <Container maxWidth='xs'>
      <ActiveGamePageHeader useActiveGameState={uag} route={PREDICTOR_ROUTE_COLLECTION.changeGameAlias} />
      {game && (
        <Stack gap={1}>
          <Alert severity='info'>
            <AlertTitle>Change game alias</AlertTitle>
            Here, you can change your name visible also to others in this game.
          </Alert>
          <TextField value={gameAlias} onChange={(e) => setGameAlias(e.target.value)} />
          <Button
            variant='contained'
            disabled={apiSetGameAlias.isLoading}
            endIcon={<Save />}
            onClick={() => apiSetGameAlias.requestWithParams((client) => patchGameAlias(client, game.id, gameAlias))}
          >
            Save alias
          </Button>
        </Stack>
      )}
    </Container>
  )
}

export default ChangeGameAlias
