import { apiGet, apiPut } from 'modules/api/predictor-api-client'
import { ClientWithController } from '../types'

const endpoint = (gameId: string, suffix?: string, suffix2?: string) =>
  `/game/${gameId}/prediction/team${suffix ? `/${suffix}` : ''}${suffix2 ? `/${suffix2}` : ''}`

const getTeamPredictions = async (client: ClientWithController, gameId: string) => await apiGet(client, endpoint(gameId, 'stage'))
const getParticipantTeamPredictions = async (client: ClientWithController, gameId: string) => await apiGet(client, endpoint(gameId, 'all'))
const getTeamPredictionsForStage = async (client: ClientWithController, gameId: string, stage: string) =>
  await apiGet(client, endpoint(gameId, 'stage', stage))
const setTeamPrediction = async (client: ClientWithController, gameId: string, teamId: string, request: { knockoutProgression: string }) =>
  await apiPut(client, endpoint(gameId, teamId), request)

export { getTeamPredictions, getTeamPredictionsForStage, setTeamPrediction, getParticipantTeamPredictions }
