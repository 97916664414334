import {
  AppBar,
  Divider,
  Drawer,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Stack,
  Toolbar,
  Typography,
  Box,
  Hidden,
  Tooltip,
} from '@mui/material'
import React, { useMemo } from 'react'
import MenuIcon from '@mui/icons-material/Menu'
import { useLocation, useNavigate } from 'react-router-dom'
import { APP_DETAILS, PREDICTOR_ROUTE_COLLECTION, STYLE_SETTINGS } from 'predictor-constants'
import MenuAuth from './MenuAuth'
import { usePredictorContext } from 'context/PredictorContext'
import { PredictorRoute } from 'types/routing'
import { WarningRounded } from '@mui/icons-material'
import { ExtendedPredictionsStatus, getExtendedPredictionsStatus, useActiveGame } from 'modules/games'

const MainMenu: React.FC = () => {
  const [mainMenuOpen, setMainMenuOpen] = React.useState(false)
  const { user, activeParticipantId, participantStatus } = usePredictorContext()
  const { game: activeGame } = useActiveGame()
  const navigate = useNavigate()
  const location = useLocation()

  const extendedPredictionsStatus = useMemo((): ExtendedPredictionsStatus | undefined => {
    if (!participantStatus || !activeGame) return undefined
    return getExtendedPredictionsStatus(participantStatus.predictionsStatus, activeGame)
  }, [participantStatus, activeGame])

  const activeGameMenuItems: PredictorRoute[] = [
    PREDICTOR_ROUTE_COLLECTION.standings,
    PREDICTOR_ROUTE_COLLECTION.fixtures,
    PREDICTOR_ROUTE_COLLECTION.groups,
    PREDICTOR_ROUTE_COLLECTION.knockout,
    PREDICTOR_ROUTE_COLLECTION.scorers,
    PREDICTOR_ROUTE_COLLECTION.rules,
    PREDICTOR_ROUTE_COLLECTION.changeGameAlias,
  ]

  const toggleMainMenu = (open: boolean) => {
    setMainMenuOpen(open)
  }

  const drawerContent: React.ReactElement = (
    <Box
      sx={{ width: STYLE_SETTINGS.DRAWER_WIDTH }}
      role='presentation'
      onClick={() => toggleMainMenu(false)}
      onKeyDown={() => toggleMainMenu(false)}
    >
      <AppBar position='static'>
        <Toolbar variant='dense'>
          <Stack direction='row' justifyContent='left' alignItems='center' width='100%'>
            {APP_DETAILS.ICON}
            <Typography variant='h6' component='div' textAlign='left' sx={{ pl: 1 }}>
              Qatar 2022
            </Typography>
          </Stack>
        </Toolbar>
      </AppBar>
      <List sx={{ pt: 2 }}>
        {user?.activeGameId && (
          <>
            <List
              sx={{
                p: 0,
              }}
            >
              {activeGameMenuItems.map((menuItem) => (
                <ListItemButton key={menuItem.path} selected={location.pathname === menuItem.path} onClick={() => navigate(menuItem.path)}>
                  <ListItemIcon>{React.createElement(menuItem.icon)}</ListItemIcon>
                  <ListItemText
                    primary={
                      <Stack direction='row' justifyContent='space-between' alignItems='center' gap={0.5}>
                        {menuItem.title}
                        {((menuItem.title === PREDICTOR_ROUTE_COLLECTION.knockout.title &&
                          extendedPredictionsStatus?.isMissingUnlockedStagePredictions) ||
                          (menuItem.title === PREDICTOR_ROUTE_COLLECTION.fixtures.title &&
                            extendedPredictionsStatus?.isMissingUnlockedFixturePredictions) ||
                          (menuItem.title === PREDICTOR_ROUTE_COLLECTION.scorers.title &&
                            extendedPredictionsStatus?.isMissingUnlockedScorerPredictions)) && (
                          <Tooltip title='Missing predictions'>
                            <WarningRounded color='warning' />
                          </Tooltip>
                        )}
                      </Stack>
                    }
                  />
                </ListItemButton>
              ))}
            </List>
          </>
        )}

        <Divider />
        <ListItemButton
          selected={location.pathname === PREDICTOR_ROUTE_COLLECTION.gameSelection.path}
          onClick={() => navigate(PREDICTOR_ROUTE_COLLECTION.gameSelection.path)}
        >
          <ListItemIcon>{React.createElement(PREDICTOR_ROUTE_COLLECTION.gameSelection.icon, { color: 'primary' })}</ListItemIcon>
          <ListItemText
            primary={PREDICTOR_ROUTE_COLLECTION.gameSelection.title}
            secondary={
              user?.gameParticipants &&
              user.gameParticipants.length > 1 && (
                <>
                  <Typography component='span' variant='subtitle2' color='secondary.main'>
                    {user?.activeGameName}
                  </Typography>
                  {activeParticipantId && (
                    <Typography component='span' variant='body2'>
                      <br />
                      {user.gameParticipants.find((x) => x.id === activeParticipantId)?.gameAlias}
                    </Typography>
                  )}
                </>
              )
            }
          />
        </ListItemButton>
        <Divider />
        <MenuAuth />
      </List>
    </Box>
  )

  return (
    <>
      <Hidden lgUp>
        <MenuIcon onClick={() => toggleMainMenu(true)} />
        <Drawer
          anchor='left'
          ModalProps={{
            keepMounted: true,
          }}
          open={mainMenuOpen}
          onClose={() => toggleMainMenu(false)}
          sx={{ zIndex: (theme) => theme.zIndex.drawer + 2 }}
        >
          {drawerContent}
        </Drawer>
      </Hidden>
      <Hidden lgDown>
        <Drawer anchor='left' variant='permanent'>
          {drawerContent}
        </Drawer>
      </Hidden>
    </>
  )
}

export default MainMenu
