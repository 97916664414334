import { InfoRounded } from '@mui/icons-material'
import {
  Box,
  Container,
  Hidden,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Theme,
  Tooltip,
  useMediaQuery,
} from '@mui/material'
import { PageNotFound, PageTitle } from 'components'
import { usePredictorApi } from 'modules/api'
import { getGame, getGameParticipants } from 'modules/api/requests'
import { Game, GameParticipantWithStatus } from 'modules/api/types'
import { PREDICTOR_ROUTE_COLLECTION } from 'predictor-constants'
import React, { useEffect } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import { GameParticipantRow } from '../components'

const GameParticipants: React.FC = () => {
  const { gameId } = useParams()
  const location = useLocation()
  const isSmDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'))
  const isMdDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'))

  if (!gameId) return <PageNotFound />

  const apiGetGame = usePredictorApi<Game>()
  const game: Game | undefined = (location.state as Game) ?? apiGetGame.data
  const apiGetGameParticipants = usePredictorApi<GameParticipantWithStatus[]>({ apiFunc: (client) => getGameParticipants(client, gameId) })
  const participantsWithStatus = apiGetGameParticipants.data

  useEffect(() => {
    if (location.state) return
    apiGetGame.requestWithParams((client) => getGame(client, gameId))
  }, [])

  return (
    <Container
      maxWidth='md'
      sx={{ maxWidth: (theme) => (isSmDown ? theme.breakpoints.xsTop : isMdDown ? theme.breakpoints.values.sm : 'inherit') }}
      disableGutters={isMdDown}
      className='test'
    >
      {game && (
        <PageTitle
          title={
            <>
              {game.name}
              <Hidden smDown> - {PREDICTOR_ROUTE_COLLECTION.gameParticipants.title}</Hidden>
            </>
          }
          icon={PREDICTOR_ROUTE_COLLECTION.gameParticipants.icon}
        />
      )}

      <Box sx={{ width: '100%', overflowX: 'auto' }}>
        {participantsWithStatus && (
          <TableContainer component={Paper}>
            <Table size='small'>
              <TableHead>
                <TableRow>
                  <TableCell>Participant</TableCell>
                  <TableCell align='center'>Status</TableCell>
                  <TableCell align='center'>
                    <Tooltip title='A disabled user cannot access the game.'>
                      <Stack direction='row' alignItems='start' justifyContent='center'>
                        <span>Enabled</span>
                        <InfoRounded sx={{ fontSize: '1.1rem', ml: 0.5 }} />
                      </Stack>
                    </Tooltip>
                  </TableCell>
                  <TableCell align='center'>
                    <Tooltip title='If a user cannot play, he is still able to view the game, but cannot add predictions.'>
                      <Stack direction='row' alignItems='start' justifyContent='center'>
                        <span>Can Play</span>
                        <InfoRounded sx={{ fontSize: '1.1rem', ml: 0.5 }} />
                      </Stack>
                    </Tooltip>
                  </TableCell>
                  <TableCell align='center'>
                    <Tooltip title='Determines whether the participant is an admin of the game. An admin can look at screens like this one.'>
                      <Stack direction='row' alignItems='start' justifyContent='center'>
                        <span>Admin</span>
                        <InfoRounded sx={{ fontSize: '1.1rem', ml: 0.5 }} />
                      </Stack>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {participantsWithStatus.map((participantWithStatus) => (
                  <GameParticipantRow
                    key={participantWithStatus.participant.id}
                    game={game}
                    participantWithStatus={participantWithStatus}
                    onChange={() => apiGetGameParticipants.request()}
                  />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </Box>
    </Container>
  )
}

export default GameParticipants
