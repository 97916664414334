import { AdminPanelSettings, CheckBox, CheckBoxOutlineBlank, ContentCopy, Delete, Group, Preview, Share } from '@mui/icons-material'
import { Avatar, Button, Card, CardActions, CardContent, Divider, Grid, IconButton, Stack, Tooltip, Typography } from '@mui/material'
import { Link } from 'components'
import { usePredictorContext } from 'context/PredictorContext'
import { usePredictorApi } from 'modules/api'
import { deleteGame } from 'modules/api/requests'
import { Game } from 'modules/api/types'
import { useConfirmationModalContext } from 'modules/ui'
import { useSnackbar } from 'notistack'
import { PREDICTOR_ROUTE_COLLECTION } from 'predictor-constants'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { isSuccessStatusCode } from 'utils/request-utils'
import { generateRouteWithId } from 'utils/route-utils'

interface IProps {
  game: Game
  onUpdatedGames: () => void
}

const GameSelectionAdminGame: React.FC<IProps> = ({ game, onUpdatedGames }) => {
  const { user, setActiveGameId, setActiveParticipantId } = usePredictorContext()
  const { showConfirm } = useConfirmationModalContext()
  const { enqueueSnackbar } = useSnackbar()
  const apiDeleteGame = usePredictorApi({ successMessage: 'Game deleted.', errorMessage: '{{error}}' })
  const navigate = useNavigate()

  const handleEditGamePoints = (gameId: string) => navigate(generateRouteWithId(PREDICTOR_ROUTE_COLLECTION.editGamePoints, gameId))

  const handleDeleteGame = async (gameId: string, gameName: string) => {
    const confirmed = await showConfirm({
      title: 'Delete game',
      renderMessage: () => (
        <>
          <Typography>
            Are you sure you want to delete game <strong>{gameName}</strong>? <br />
            This is irreversable.
          </Typography>
        </>
      ),
    })
    if (!confirmed) return

    const response = await apiDeleteGame.requestWithParams((client) => deleteGame(client, gameId))
    if (isSuccessStatusCode(response)) onUpdatedGames()
  }

  const generateInviteLink = (gameId: string): string =>
    `${window.location.origin}${generateRouteWithId(PREDICTOR_ROUTE_COLLECTION.gameInvite, gameId)}`

  const handleCopyInviteLink = (gameId: string) => {
    navigator.clipboard.writeText(generateInviteLink(gameId))
    enqueueSnackbar('Invite link copied.')
  }

  const setActiveGame = (gameId: string) => {
    setActiveGameId(gameId)
    setActiveParticipantId(undefined)
  }

  if (!user) return <></>

  return (
    <Grid item xs={12} md={4} key={game.id}>
      <Card>
        <CardContent>
          <Stack gap={2}>
            <Stack direction='row' justifyContent='between' alignItems='center'>
              <Stack flexGrow={1}>
                <Typography variant='subtitle2' color='text.secondary'>
                  {game.tournament.name}
                </Typography>
                <Typography variant='h6' color={game.id === user.activeGameId ? 'secondary.main' : 'inherit'}>
                  {game.name}
                </Typography>
              </Stack>
              <Stack direction='row' gap={1}>
                {game.userParticipant.isViewOnly && (
                  <Tooltip title='View only'>
                    <Avatar sx={{ width: 30, height: 30, bgcolor: (theme) => theme.palette.emphasis }}>
                      <Preview fontSize='small' />
                    </Avatar>
                  </Tooltip>
                )}
                {game.userParticipant.isGameAdmin && (
                  <Tooltip title='Admin'>
                    <Avatar sx={{ width: 30, height: 30, bgcolor: (theme) => theme.palette.success.main }}>
                      <AdminPanelSettings fontSize='small' />
                    </Avatar>
                  </Tooltip>
                )}
              </Stack>
            </Stack>
            <Divider />
            <Stack gap={1}>
              <Stack direction='row' alignItems='center' gap={1}>
                <Group sx={{ color: 'emphasis' }} fontSize='small' />
                <Typography variant='body2'>
                  <Link
                    onClick={() => navigate(generateRouteWithId(PREDICTOR_ROUTE_COLLECTION.gameParticipants, game.id), { state: game })}
                  >
                    {game.participants.length === 1 ? '1 participant' : `${game.participants.length} participants`}
                  </Link>
                </Typography>
              </Stack>
              <Stack direction='row' alignItems='center' gap={1}>
                <Share sx={{ color: 'emphasis' }} fontSize='small' />
                <Typography variant='body2' sx={{ flexGrow: 1 }}>
                  Invite Link
                </Typography>
                <Stack
                  direction='row'
                  alignItems='center'
                  justifyContent='space-between'
                  gap={1}
                  sx={{ background: (theme) => theme.palette.emphasis }}
                >
                  <Typography
                    variant='caption'
                    color='text.secondary'
                    sx={{
                      width: '100px',
                      p: 1,
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                    }}
                  >
                    {generateInviteLink(game.id)}
                  </Typography>
                  <IconButton size='small' color='primary' onClick={() => handleCopyInviteLink(game.id)}>
                    <ContentCopy />
                  </IconButton>
                </Stack>
              </Stack>
            </Stack>
          </Stack>
        </CardContent>
        <Divider />
        <CardActions sx={{ alignItems: 'center', justifyContent: 'space-between' }}>
          <Button
            size='small'
            disabled={game.id === user.activeGameId}
            startIcon={game.id === user.activeGameId ? <CheckBox /> : <CheckBoxOutlineBlank />}
            onClick={() => setActiveGame(game.id)}
          >
            {game.id === user.activeGameId ? 'Active' : 'Make active'}
          </Button>

          <Stack gap={0.5} alignItems='end'>
            <Button
              size='small'
              color='secondary'
              endIcon={React.createElement(PREDICTOR_ROUTE_COLLECTION.editGamePoints.icon)}
              onClick={() => handleEditGamePoints(game.id)}
              disabled={game.tournament.isStarted}
            >
              <Tooltip title={PREDICTOR_ROUTE_COLLECTION.editGamePoints.title}>
                <span>Edit Points</span>
              </Tooltip>
            </Button>

            <Button size='small' color='error' endIcon={<Delete />} onClick={() => handleDeleteGame(game.id, game.name)}>
              Delete
            </Button>
          </Stack>
        </CardActions>
      </Card>
    </Grid>
  )
}

export default GameSelectionAdminGame
