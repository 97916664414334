import { Box, Grid, Stack, Tab, Tabs, Theme, Tooltip, useMediaQuery } from '@mui/material'
import { StageTeam, Game, TeamIdentifier } from 'modules/api/types'
import React, { useCallback, useMemo, useState } from 'react'
import KnockoutSegment from './KnockoutSegment'
import { usePredictorApi } from 'modules/api'
import { isSuccessStatusCode } from 'utils/request-utils'
import { setTeamPrediction } from 'modules/api/requests'
import { StageView } from 'types/stages'
import { usePredictorContext } from 'context/PredictorContext'
import { WarningRounded } from '@mui/icons-material'
import { isMissingTeamPredictions } from '../utils'

interface IProps {
  game: Game
  groupedStages: StageView[]
  onStagesUpdated: () => void
  viewOnly: boolean
  mode: 'Actual' | 'MyPredictions' | 'ParticipantPredictions'
}

const KnockoutTabs: React.FC<IProps> = ({ game, groupedStages, onStagesUpdated, viewOnly, mode }) => {
  const { participantStatus, refreshParticipantStatus } = usePredictorContext()
  const isMdUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'))
  const [selectedTab, setSelectedTab] = useState(groupedStages[0].key)
  const currentStageView = useMemo(() => groupedStages.find((x) => x.key === selectedTab), [selectedTab, groupedStages])
  const [knockoutSelectionDisabled, setKnockoutSelectionDisabled] = useState(false)

  const apiSetStageTeam = usePredictorApi()

  const onSelect = async (segment: StageTeam, team: TeamIdentifier) => {
    setKnockoutSelectionDisabled(true)

    const response = await apiSetStageTeam.requestWithParams((client) =>
      setTeamPrediction(client, game.id, team.id, { knockoutProgression: segment.stage }),
    )

    if (!isSuccessStatusCode(response)) return

    refreshParticipantStatus()

    await onStagesUpdated()
    setKnockoutSelectionDisabled(false)
  }

  const isStageMissingTeamPredictions = useCallback(
    (stage: StageView) => isMissingTeamPredictions(stage, participantStatus),
    [participantStatus],
  )

  return (
    <>
      <Box
        sx={{
          borderBottom: 1,
          borderColor: 'divider',
          width: '100%',
          backgroundColor: 'background.paper',
          zIndex: (theme) => theme.zIndex.appBar,
        }}
      >
        <Tabs value={selectedTab} onChange={(e, v) => setSelectedTab(v)} centered={isMdUp} variant={isMdUp ? 'standard' : 'fullWidth'}>
          {groupedStages.map((stage) => (
            <Tab
              sx={{ minWidth: '80px' }}
              wrapped={!isMdUp}
              key={stage.key}
              label={
                <Stack direction='row' alignItems='center' gap={0.5}>
                  {isMdUp ? stage.displayName : stage.displayName}
                  {isStageMissingTeamPredictions(stage) && (
                    <Tooltip title='Missing predictions'>
                      <WarningRounded fontSize='small' color='warning' />
                    </Tooltip>
                  )}
                </Stack>
              }
              value={stage.key}
            />
          ))}
        </Tabs>
      </Box>
      {currentStageView && (
        <Grid container p={2} spacing={2} justifyContent='center' pt='48px'>
          {currentStageView.segments.map((segment) => (
            <Grid item key={`${mode}_${segment.stage}`} xs={12} md={6} lg={3}>
              <KnockoutSegment
                segment={segment}
                disabledButtons={knockoutSelectionDisabled}
                viewOnly={viewOnly}
                onSelect={async (selectedTeam) => await onSelect(segment, selectedTeam)}
              />
            </Grid>
          ))}
        </Grid>
      )}
    </>
  )
}

export default KnockoutTabs
