import { useAuth0 } from '@auth0/auth0-react'
import { AxiosError, AxiosResponse, CanceledError } from 'axios'
import { useEffect, useState } from 'react'
import { getApiClient } from 'modules/api/predictor-api-client'
import { useSnackbar } from 'notistack'
import { ClientWithController } from '../types'
import { usePredictorContext } from 'context/PredictorContext'

interface IPredictorApi<T> {
  data?: T
  error?: string
  isLoading: boolean
  request: (optionOverrides?: Partial<PredictorApiOptions>) => Promise<AxiosResponse | undefined>
  requestWithParams: (
    apiFunc: (client: ClientWithController) => Promise<AxiosResponse>,
    optionOverrides?: Partial<PredictorApiOptions>,
  ) => Promise<AxiosResponse | undefined>
}

interface PredictorApiOptions {
  apiFunc?: (client: ClientWithController) => Promise<AxiosResponse>
  successMessage?: string
  errorMessage?: string
  triggerOnStart: boolean
}

const defaultOptions: PredictorApiOptions = { triggerOnStart: true, errorMessage: 'Request failed. {{error}}' }

const usePredictorApi = <T>(predictorApiOptions?: Partial<PredictorApiOptions>): IPredictorApi<T> => {
  const { activeParticipantId } = usePredictorContext()
  const [data, setData] = useState<T>()
  const [error, setError] = useState<string>()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const { getAccessTokenSilently } = useAuth0()
  const snackbar = useSnackbar()
  const [abortController, setAbortController] = useState<AbortController>()

  let options = predictorApiOptions ? { ...defaultOptions, ...predictorApiOptions } : { ...defaultOptions }

  const updateOptions = (overrides?: Partial<PredictorApiOptions>) => {
    if (!overrides) return

    options = { ...options, ...overrides }
  }

  const request = async (optionOverrides?: Partial<PredictorApiOptions>): Promise<AxiosResponse | undefined> => {
    if (predictorApiOptions?.apiFunc === undefined) throw new Error('apiFunc must exist. Otherwise call requestWithParams')
    return requestWithParams(predictorApiOptions?.apiFunc, optionOverrides)
  }

  const requestWithParams = async (
    apiFunc: (client: ClientWithController) => Promise<AxiosResponse>,
    optionOverrides?: Partial<PredictorApiOptions>,
  ): Promise<AxiosResponse | undefined> => {
    if (abortController) {
      abortController.abort()
    }
    updateOptions(optionOverrides)

    setIsLoading(true)
    setError(undefined)

    const client = await getApiClient(getAccessTokenSilently, activeParticipantId)
    const newAbortController = new AbortController()
    setAbortController(newAbortController)
    try {
      const response = await apiFunc({ client, abortController: newAbortController })
      setIsLoading(false)
      setData(response.data as T)
      if (options.successMessage) snackbar?.enqueueSnackbar(options.successMessage, { variant: 'success' })
      return response
    } catch (e) {
      if (e instanceof CanceledError) return

      setIsLoading(false)

      if (typeof e === 'string') {
        setError(e)
      } else if (e instanceof AxiosError) {
        if (e.response?.status === 403) setError('Operation not allowed.')
        else if (e.response?.status === 400) setError(e.response?.data)
        else setError(e.message)
      } else if (e instanceof Error) {
        setError(e.message)
      }
    }
  }

  useEffect(() => {
    if (options.errorMessage && error) snackbar?.enqueueSnackbar(options.errorMessage.replaceAll('{{error}}', error), { variant: 'error' })
  }, [error])

  useEffect(() => {
    if (options?.apiFunc && options.triggerOnStart) {
      request()
    }
  }, [])

  return { data, error, isLoading, request, requestWithParams }
}

export default usePredictorApi
