import { Avatar } from '@mui/material'

export const APP_DETAILS = {
  TITLE: 'Predictions',
  SUBTITLE: 'World Cup 2022',
  ICON: (
    <Avatar
      sx={{ width: '30px', height: '30px' }}
      variant='square'
      alt='Predictor User Portal'
      src={`${process.env.PUBLIC_URL}/images/world-cup-2022-logo.png`}
    />
  ),
  MAINLOGO: <img src={`${process.env.PUBLIC_URL}/images/worldcup2022logo.webp`} alt='World Cup 2022' style={{ maxWidth: '100%' }} />,
  WELCOMEMESSAGE: 'Welcome to the predictions game for World Cup 2022. Please sign in to continue.',
}

export const INPUT_DATE_FORMAT = 'dd/MM/yyyy HH:mm'
export const DISPLAY_DATE_FORMAT = 'dd MMM yyyy HH:mm'
