import { Typography } from '@mui/material'
import { PREDICTOR_ROUTE_COLLECTION } from 'predictor-constants'
import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

const Callback = () => {
  const navigate = useNavigate()

  useEffect(() => {
    const redirectUrl = localStorage.getItem('redirect-url')
    if (redirectUrl && redirectUrl !== window.location.href) {
      window.location.replace(redirectUrl)
      return
    }

    navigate(PREDICTOR_ROUTE_COLLECTION.home.path)
  }, [])

  return (
    <Typography variant='body1' p={3}>
      You are being redirected.
    </Typography>
  )
}

export default Callback
