import { useAuth0 } from '@auth0/auth0-react'
import { CircleRounded } from '@mui/icons-material'
import { Alert, Box, Button, Checkbox, Container, Paper, Stack, Tooltip, Typography } from '@mui/material'
import { APP_DETAILS } from 'predictor-constants'
import React, { useEffect, useState } from 'react'

const Login: React.FC<{ userError?: string }> = ({ userError }): JSX.Element => {
  const { loginWithRedirect, logout, error, isAuthenticated } = useAuth0()
  const [understandCheckbox, setUnderstandCheckbox] = useState(false)
  const [policyOpen, setPolicyOpen] = useState(false)

  useEffect(() => {
    localStorage.setItem('redirect-url', window.location.href)
  }, [])

  return (
    <Box
      sx={{
        background: '#EEEEEE',
        color: (theme) => theme.palette.text.primary,
        minHeight: '100vh',
        p: 0,
        m: 0,
      }}
    >
      <Container component='main' maxWidth='xs'>
        <Stack height='100vh' justifyContent='center' p={1}>
          <Paper elevation={4}>
            <Stack
              direction='column'
              gap={3}
              justifyContent='space-between'
              alignItems='center'
              sx={{
                backgroundColor: 'background.default',
                px: 4,
                maxHeight: '80vh',
                minHeight: '40vh',
                py: 5,
              }}
            >
              {APP_DETAILS.MAINLOGO}
              <Typography variant='body1'>{APP_DETAILS.WELCOMEMESSAGE}</Typography>

              <Stack direction='row' gap={1}>
                <Checkbox checked={understandCheckbox} onChange={(e) => setUnderstandCheckbox(e.target.checked)} />
                <Tooltip
                  open={policyOpen}
                  disableTouchListener
                  disableHoverListener
                  disableFocusListener
                  onClick={() => setPolicyOpen(!policyOpen)}
                  componentsProps={{
                    tooltip: {
                      sx: {
                        bgcolor: (theme) => theme.palette.grey[800],
                        boxShadow: 3,
                      },
                    },
                  }}
                  title={
                    <Stack gap={1} py={1} onClick={() => setPolicyOpen(false)}>
                      <Typography variant='body2' sx={{ display: 'flex', direction: 'row', gap: 1, alignItems: 'center' }}>
                        <CircleRounded fontSize='small' sx={{ fontSize: 10, color: 'emphasis' }} />
                        My email may be used for important notifications and will be visible to administrators and creators of the game.
                      </Typography>
                      <Typography variant='body2' sx={{ display: 'flex', direction: 'row', gap: 1, alignItems: 'center' }}>
                        <CircleRounded fontSize='small' sx={{ fontSize: 10, color: 'emphasis' }} />
                        My name and picture retrieved via my social login will be visible to all game participants (if applicable).
                      </Typography>
                      <Button sx={{ color: (theme) => theme.palette.grey[100] }} onClick={() => setPolicyOpen(false)}>
                        Close
                      </Button>
                    </Stack>
                  }
                >
                  <Typography variant='body2' sx={{ textDecoration: 'underline', cursor: 'pointer' }}>
                    I understand how my data is used by joining the game.
                  </Typography>
                </Tooltip>
              </Stack>

              {!isAuthenticated && !error && (
                <Button variant='contained' size='large' onClick={() => loginWithRedirect()} fullWidth disabled={!understandCheckbox}>
                  Sign in to continue
                </Button>
              )}
              {(error || userError) && (
                <>
                  <Alert severity='error'>
                    {error && error.message}
                    {userError &&
                      (userError === 'Network Error' ? 'Could not connect to server. Please contact administrator.' : userError)}
                  </Alert>
                  <Button variant='contained' size='large' onClick={() => logout({ returnTo: window.location.origin })} fullWidth>
                    Logout
                  </Button>
                </>
              )}
            </Stack>
          </Paper>
        </Stack>
      </Container>
    </Box>
  )
}

export default Login
