import { Stack, TextField, Theme, Typography, useMediaQuery } from '@mui/material'
import { TeamName } from 'components'
import { Fixture, Game } from 'modules/api/types'
import React, { useCallback, useEffect, useState } from 'react'
import { getFixtureCodeDisplayName } from 'utils/stage-utils'

interface IProps {
  game: Game
  fixture: Fixture
  onScoreInputChanged?: (homeScore: number, awayScore: number) => void
  size?: 'default' | 'small'
  showOutcomeDescription?: boolean
}
const FixtureScore: React.FC<IProps> = ({ game, fixture, onScoreInputChanged, size = 'default', showOutcomeDescription = false }) => {
  const isFixtureLocked = () => game.predictionRules.lockedFixtures.includes(fixture.id)

  const isSmDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'))

  const isSmallSize = useCallback((): boolean => isSmDown || size === 'small', [isSmDown, size])

  const [homeScore, setHomeScore] = useState<number | undefined>(fixture.prediction?.homeTeamScore ?? undefined)
  const [awayScore, setAwayScore] = useState<number | undefined>(fixture.prediction?.awayTeamScore ?? undefined)

  const scoreInputChanged = (side: 'home' | 'away', newScore: number) => {
    if (side === 'home') {
      if (isNaN(newScore)) setHomeScore(undefined)
      else setHomeScore(newScore)
    } else if (side === 'away') {
      if (isNaN(newScore)) setAwayScore(undefined)
      else setAwayScore(newScore)
    }
  }

  useEffect(() => {
    if (homeScore === undefined || awayScore === undefined) return

    if (fixture.prediction && fixture.prediction.homeTeamScore === homeScore && fixture.prediction.awayTeamScore === awayScore) return

    onScoreInputChanged && onScoreInputChanged(homeScore, awayScore)
  }, [homeScore, awayScore])

  const renderScore = (side: 'home' | 'away', actualScore: number): React.ReactElement => {
    if (isFixtureLocked())
      return (
        <Typography variant='body1' fontSize='1.1rem' mx='10px'>
          {fixture.fixtureStatus === 'NotStarted' ? '' : actualScore}
        </Typography>
      )
    return (
      <TextField
        disabled={game.userParticipant.isViewOnly}
        size='small'
        sx={{ width: 40, textAlign: 'center' }}
        type='number'
        inputProps={{ min: '0', className: 'hide-arrows', style: { padding: '5px' } }}
        value={side === 'home' ? homeScore ?? '' : awayScore ?? ''}
        onChange={(e) => scoreInputChanged(side, parseInt(e.target.value))}
      ></TextField>
    )
  }

  return (
    <Stack direction='row' alignItems='start' width='100%' gap={1}>
      <Stack flexGrow={1} flexBasis={0} alignItems='end'>
        <TeamName
          team={fixture.homeTeam}
          flag='end'
          showFullName={!isSmallSize()}
          placeholder={getFixtureCodeDisplayName(fixture.homeTeamPlaceholder)}
        />
      </Stack>
      <Stack>
        <Stack width='100px' direction='row' justifyContent='center' alignItems='center' flexShrink={0} gap={1}>
          {renderScore('home', fixture.homeTeamScore)}
          <Typography>-</Typography>
          {renderScore('away', fixture.awayTeamScore)}
        </Stack>
        {showOutcomeDescription && fixture.outcomeDescription && (
          <Typography variant='caption' textAlign='center'>
            ({fixture.outcomeDescription})
          </Typography>
        )}
      </Stack>
      <Stack flexGrow={1} flexBasis={0}>
        <TeamName
          team={fixture.awayTeam}
          showFullName={!isSmallSize()}
          placeholder={getFixtureCodeDisplayName(fixture.awayTeamPlaceholder)}
        />
      </Stack>
    </Stack>
  )
}

export default FixtureScore
