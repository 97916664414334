import { Paper, Stack, Tooltip, Typography, TypographyTypeMap } from '@mui/material'
import { DefaultComponentProps } from '@mui/material/OverridableComponent'
import React, { useMemo, useState } from 'react'

interface IProps {
  team?: { code: string; name: string }
  flag?: 'start' | 'end' | 'none'
  showFullName?: boolean
  placeholder?: string
  typographyProps?: DefaultComponentProps<TypographyTypeMap>
  hideName?: boolean
}

const TeamName: React.FC<IProps> = ({ team, flag = 'start', placeholder, typographyProps, showFullName = true, hideName = false }) => {
  const [flagImgExists, setFlagImgExists] = useState(true)

  const teamUrl = useMemo(() => `https://cloudinary.fifa.com/api/v1/picture/flags-sq-2/${team?.code}`, [team])

  const flagDiv = !team ? (
    <></>
  ) : (
    <Tooltip title={team?.name ?? 'Team'}>
      <Paper
        component='img'
        sx={{
          width: 'auto',
          height: 'auto',
          maxWidth: '25px',
          background: 'white',
        }}
        alt={team?.name ?? 'Placeholder'}
        src={flagImgExists ? teamUrl : '/images/flagPlaceholder.png'}
        onError={() => {
          setFlagImgExists(false)
        }}
      />
    </Tooltip>
  )

  return (
    <Stack direction='row' alignItems='center' gap={1} display='inline-flex'>
      {flag === 'start' && flagDiv}
      {!hideName && (
        <Typography {...{ component: 'div', ...typographyProps }}>
          {!team && placeholder}
          {team && (showFullName ? team.name : team.code)}
        </Typography>
      )}
      {flag === 'end' && flagDiv}
    </Stack>
  )
}

export default React.memo(TeamName)
