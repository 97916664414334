import { Check, PriorityHigh, WarningRounded } from '@mui/icons-material'
import { Stack, Tooltip, Typography } from '@mui/material'
import React, { useCallback } from 'react'
import { ExtendedPredictionsStatus } from '../types'

interface IProps {
  status: ExtendedPredictionsStatus
}

const GameParticipantStatus: React.FC<IProps> = ({ status }) => {
  const renderMissingPredictions = useCallback(
    (): React.ReactElement => (
      <Stack gap={1}>
        {status.isMissingUnlockedFixturePredictions && (
          <Typography variant='body2'>Missing {status.incompleteUnlockedFixturePredictions.length} fixture predictions.</Typography>
        )}
        {status.isMissingUnlockedStagePredictions && (
          <Typography variant='body2'>Missing {status.missingUnlockedStagePredictions.length} stage predictions.</Typography>
        )}
        {status.isMissingUnlockedScorerPredictions && (
          <Typography variant='body2'>Missing {status.missingUnlockedScorerPredictions} predicted scorers.</Typography>
        )}
      </Stack>
    ),
    [status],
  )
  return (
    <>
      {!status.isMissingPredictions && (
        <Tooltip title='No missing predictions'>
          <Check color='success' />
        </Tooltip>
      )}
      {!status.isMissingUnlockedPredictions && status.isMissingPredictions && (
        <Tooltip title='Missing already locked predictions'>
          <PriorityHigh color='disabled' />
        </Tooltip>
      )}
      {status.isMissingUnlockedPredictions && (
        <Tooltip title={renderMissingPredictions()}>
          <WarningRounded color='warning' />
        </Tooltip>
      )}
    </>
  )
}

export default GameParticipantStatus
