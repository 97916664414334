import { apiGet } from 'modules/api/predictor-api-client'
import { ClientWithController } from '../types'

const endpoint = (gameId: string, suffix?: string) => `/game/${gameId}/prediction/group${suffix ? `/${suffix}` : ''}`

const getGroupPredictions = async (client: ClientWithController, gameId: string) => await apiGet(client, endpoint(gameId))
const getParticipantGroupPredictions = async (client: ClientWithController, gameId: string, gameParticipantId: string) =>
  await apiGet(client, endpoint(gameId, `participant/${gameParticipantId}`))

const getGroupPrediction = async (client: ClientWithController, gameId: string, group: string) =>
  await apiGet(client, endpoint(gameId, group))

export { getGroupPredictions, getGroupPrediction, getParticipantGroupPredictions }
