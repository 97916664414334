import { Switch, TextField } from '@mui/material'
import React from 'react'

interface IProps {
  value: string
  onChange: (newValue: string) => void
}

type ValueType = 'string' | 'boolean' | 'number'

const GameOptionField: React.FC<IProps> = ({ value, onChange }) => {
  const getValueType = (value: string): ValueType => {
    if (['true', 'false'].includes(value.toLowerCase())) return 'boolean'
    if (isNaN(Number(value))) return 'string'
    return 'number'
  }

  const valueType = getValueType(value)

  const renderTextField = () => (
    <TextField variant='outlined' error={value === ''} required value={value} onChange={(e) => onChange(e.target.value)} />
  )

  const renderSwitchField = () => (
    <Switch checked={value.toLowerCase() === 'true'} onChange={(e) => onChange(e.target.checked.toString())} />
  )

  const renderNumberField = () => (
    <TextField
      type='number'
      variant='outlined'
      inputProps={{ min: 0 }}
      required
      value={Number(value)}
      onChange={(e) => onChange(e.target.value.toString())}
    />
  )

  switch (valueType) {
    case 'boolean':
      return renderSwitchField()
    case 'number':
      return renderNumberField()
    default:
      return renderTextField()
  }
}

export default GameOptionField
