import { Alert, AlertTitle, Container } from '@mui/material'
import { LoadingBox, PageNotFound, PageTitle } from 'components'
import { usePredictorContext } from 'context/PredictorContext'
import { usePredictorApi } from 'modules/api'
import { joinGame } from 'modules/api/requests'
import { PREDICTOR_ROUTE_COLLECTION } from 'predictor-constants'
import { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { generateRouteWithId } from 'utils/route-utils'

const GameInvite = () => {
  const { gameId } = useParams()
  const navigate = useNavigate()
  const apiJoinGame = usePredictorApi({ successMessage: 'You have successfully joined the game.' })
  const { user, refreshUser } = usePredictorContext()

  if (!gameId) return <PageNotFound />

  useEffect(() => {
    if (gameId) apiJoinGame.requestWithParams((client) => joinGame(client, gameId))
  }, [])

  useEffect(() => {
    if (!apiJoinGame.data || apiJoinGame.isLoading || apiJoinGame.error) return
    refreshUser()
  }, [apiJoinGame.data])

  useEffect(() => {
    if (!user || !apiJoinGame.data) return
    if (user.activeGameId) navigate(generateRouteWithId(PREDICTOR_ROUTE_COLLECTION.fixtures, user.activeGameId))
    else navigate(PREDICTOR_ROUTE_COLLECTION.gameSelection.path)
  }, [user])

  return (
    <Container maxWidth='md'>
      <PageTitle title={PREDICTOR_ROUTE_COLLECTION.gameInvite.title} icon={PREDICTOR_ROUTE_COLLECTION.gameInvite.icon} />

      {apiJoinGame.isLoading && <LoadingBox />}

      {apiJoinGame.error && (
        <Alert severity='error'>
          <AlertTitle>Error</AlertTitle>
          You could not be added to the game. Please contact the administrator.
        </Alert>
      )}
    </Container>
  )
}

export default GameInvite
