import { Circle } from '@mui/icons-material'
import { Stack, Typography } from '@mui/material'
import { Game } from 'modules/api/types'
import React, { ReactElement, useCallback } from 'react'
import { format as formatDate } from 'date-fns'
import { DISPLAY_DATE_FORMAT } from 'predictor-constants'

interface IProps {
  game: Game
}

const RuleList: React.FC<IProps> = ({ game }) => {
  const getRules = useCallback((): (string | ReactElement)[] => {
    if (!game) return []

    const tournamentStartDate = formatDate(new Date(game.tournament.startDate), DISPLAY_DATE_FORMAT)

    const rules = []

    rules.push(
      'All fixture prediction scores are up to 90 minutes + injury time. Extra time is not considered for predictions. That means that a knockout match can still end in draw.',
    )

    if (game.predictionRules.groupPredictionsAllowed) {
      rules.push(
        'All fixture predictions must be set before the start of the respective match.',
        <>
          Group position predictions must be set before <strong>{tournamentStartDate}</strong>, when the tournament starts.
        </>,
      )
    } else {
      rules.push(
        <>
          All group fixture predictions must be entered before <strong> {tournamentStartDate}</strong>, when the tournament starts.
        </>,
        'All knockout fixture predictions must be set before the start of the respective match.',
        'Group position predictions are automatically set depending on the fixture prediction scores.',
      )
    }

    rules.push(
      <>
        Knockout predictions, up to the winner, must be set before <strong>{tournamentStartDate}</strong>, when the tournament starts.
      </>,
    )
    if (game.predictionRules.maxNumberOfScorers > 0) {
      rules.push(
        <>
          <strong>{game.predictionRules.maxNumberOfScorers} players </strong> must be selected as predicted scorers. Points for each goal
          scored by one of the chosen players are awarded for goals during normal time or extra time. Refer to the points section for
          details.
        </>,
      )
    }
    rules.push(
      'Participants may be awarded leader bonuses. If set up, these points are awarded to the leader of that point code. E.g. If there is a leader bonus of 20 points on Exact Score (ES), the participant(s) having the most exact score predictions are awarded an extra 20 points. The leader bonus points awarded are lost if another participant becomes the leader. If multiple participants lead, all of them will get the leader bonus.',
      'If two or more participants have the same amount of points, they are then sorted by Exact Score (ES), then Correct Outcome (CO).',
    )
    rules.push('Matches for 3rd and 4th places are not part of this game.')

    return rules
  }, [game])

  return (
    <Stack gap={1}>
      {getRules().map((rule, index) => (
        <Stack key={`rule-${index}`} sx={{ p: 1 }}>
          <Stack direction='row' alignItems='center' gap={1}>
            <Circle sx={{ fontSize: '0.5rem', color: 'emphasis' }} />
            <Typography variant='body2'>{rule}</Typography>
          </Stack>
        </Stack>
      ))}
    </Stack>
  )
}

export default RuleList
