import { Container, Divider, Stack } from '@mui/material'
import { ParticipantViewSelect } from 'components'
import { usePredictorApi } from 'modules/api'
import { getParticipantTeamPredictions, getTeamPredictions, getTournamentStages } from 'modules/api/requests'
import { ParticipantStagePredictions, StageTeam } from 'modules/api/types'
import { ActiveGamePageHeader, useActiveGame } from 'modules/games'
import { PREDICTOR_ROUTE_COLLECTION } from 'predictor-constants'
import { useEffect, useState } from 'react'
import { StageView } from 'types/stages'
import { getGroupedActualStages, getGroupedPredictionStages } from 'utils/stage-utils'
import { KnockoutTabs } from '../components'

type viewParticipantType = 'actual' | string | undefined

const Knockout = () => {
  const uag = useActiveGame()
  const { game } = uag

  const getStagePredictionsApi = usePredictorApi<StageTeam[]>()
  const stagePredictions = getStagePredictionsApi.data

  const getParticipantStagePredictionsApi = usePredictorApi<ParticipantStagePredictions[]>()
  const participantStagePredictions = getParticipantStagePredictionsApi.data

  const getStagesApi = usePredictorApi<StageTeam[]>()
  const actualStages = getStagesApi.data

  const [viewParticipant, setViewParticipant] = useState<viewParticipantType>('actual')

  useEffect(() => {
    if (!game) return
    getStagesApi.requestWithParams((client) => getTournamentStages(client, game.tournamentId))
    getStagePredictionsApi.requestWithParams((client) => getTeamPredictions(client, game.id))

    if (game.predictionRules.teamPredictionsLocked) {
      getParticipantStagePredictionsApi.requestWithParams((client) => getParticipantTeamPredictions(client, game.id))
    }

    if (!game.predictionRules.teamPredictionsLocked) {
      setViewParticipant(game.userParticipant.id)
      return
    }

    setViewParticipant('actual')
  }, [game])

  const getViewStages = (): StageView[] => {
    if (!game) return []

    if (viewParticipant === 'actual')
      // Actual stages
      return getGroupedActualStages(actualStages ?? []).filter((x) => !x.isGroupStage)

    if (viewParticipant === game.userParticipant.id)
      return getGroupedPredictionStages(stagePredictions ?? []).filter((x) => !x.isGroupStage)

    return getGroupedPredictionStages(participantStagePredictions?.find((x) => x.participant.id === viewParticipant)?.stages ?? []).filter(
      (x) => !x.isGroupStage,
    )
  }

  const getMode = (): 'Actual' | 'MyPredictions' | 'ParticipantPredictions' => {
    if (!game) return 'Actual'

    if (viewParticipant === 'actual')
      // Actual stages
      return 'Actual'

    if (viewParticipant === game.userParticipant.id) return 'MyPredictions'

    return 'ParticipantPredictions'
  }

  return (
    <Container maxWidth='lg' disableGutters>
      <ActiveGamePageHeader useActiveGameState={uag} route={PREDICTOR_ROUTE_COLLECTION.knockout} />
      {game && stagePredictions && actualStages && (
        <Stack gap={1} alignItems='center'>
          {game.predictionRules.teamPredictionsLocked && participantStagePredictions && (
            <>
              <ParticipantViewSelect
                game={game}
                includeRealOption={true}
                participants={participantStagePredictions.map((x) => x.participant)?.sort((a, b) => a.gameAlias.localeCompare(b.gameAlias))}
                selectedView={viewParticipant}
                onChange={setViewParticipant}
              />
              <Divider />
            </>
          )}
          <KnockoutTabs
            game={game}
            groupedStages={getViewStages()}
            mode={getMode()}
            onStagesUpdated={async () => await getStagePredictionsApi.requestWithParams((client) => getTeamPredictions(client, game.id))}
            viewOnly={viewParticipant !== game.userParticipant.id || game.predictionRules.teamPredictionsLocked}
          />
        </Stack>
      )}
    </Container>
  )
}

export default Knockout
