import { InfoRounded } from '@mui/icons-material'
import { FormControlLabel, Paper, Stack, Switch, TextField, Theme, Tooltip, Typography, useMediaQuery } from '@mui/material'
import { GameConfigurationGamePoint } from 'modules/api/types'
import React from 'react'

interface IProps {
  configurationPoints: GameConfigurationGamePoint[]
  onChange: (newPoints: GameConfigurationGamePoint[]) => void
}

const GamePointsForm: React.FC<IProps> = ({ configurationPoints, onChange }) => {
  const isMdDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'))

  const onPointChanged = (code: string, newValue: GameConfigurationGamePoint) => {
    const newPoints = [...configurationPoints]
    const point = newPoints.find((x) => x.code === code)
    if (!point) return
    point.isEnabled = newValue.isEnabled
    point.pointValue = newValue.pointValue
    point.bonusForPointLeader = newValue.bonusForPointLeader
    onChange(newPoints)
  }

  return (
    <>
      <Stack gap={1}>
        {configurationPoints.map((point) => (
          <Paper key={point.code} sx={{ p: 2 }} variant='elevation'>
            <Stack
              direction={isMdDown ? 'column' : 'row'}
              gap={isMdDown ? 2 : 1}
              justifyContent='space-between'
              alignItems={isMdDown ? 'start' : 'center'}
            >
              <Stack flexGrow={1} gap={1}>
                <Typography variant='h6'>
                  {point.name} ({point.code})
                </Typography>
                <Typography variant='body2'>{point.description}</Typography>
              </Stack>
              <Stack flexShrink={0} width={isMdDown ? '100%' : '120px'} alignItems={isMdDown ? 'start' : 'center'}>
                <FormControlLabel
                  labelPlacement='end'
                  sx={{ ml: 0 }}
                  control={
                    <Switch
                      checked={point.isEnabled}
                      onChange={(e) => onPointChanged(point.code, { ...point, isEnabled: e.target.checked })}
                    />
                  }
                  label={point.isEnabled ? 'Enabled' : 'Disabled'}
                />
              </Stack>
              <Stack flexShrink={0} width={isMdDown ? '100%' : '120px'} alignItems={isMdDown ? 'start' : 'center'}>
                <TextField
                  fullWidth
                  disabled={!point.isEnabled}
                  type='number'
                  variant='outlined'
                  label='Point Value'
                  inputProps={{ min: 0 }}
                  required
                  value={point.pointValue}
                  onChange={(e) => onPointChanged(point.code, { ...point, pointValue: Number(e.target.value) })}
                />
              </Stack>
              <Stack flexShrink={0} width={isMdDown ? '100%' : '120px'} alignItems={isMdDown ? 'start' : 'center'}>
                <TextField
                  fullWidth
                  disabled={!point.isEnabled}
                  type='number'
                  variant='outlined'
                  label={
                    <Tooltip title='Bonus points for the user with most occurences for this point.'>
                      <Stack direction='row' alignItems='center' gap={1}>
                        <div>Leader Bonus</div>
                        <InfoRounded />
                      </Stack>
                    </Tooltip>
                  }
                  inputProps={{ min: 0 }}
                  value={point.bonusForPointLeader}
                  onChange={(e) => onPointChanged(point.code, { ...point, bonusForPointLeader: Number(e.target.value) })}
                />
              </Stack>
            </Stack>
          </Paper>
        ))}
      </Stack>
    </>
  )
}

export default GamePointsForm
