import { apiGet, apiPut } from 'modules/api/predictor-api-client'
import { ClientWithController, SetScorerRequest } from '../types'

const endpoint = (gameId: string, suffix?: string) => `/game/${gameId}/prediction/scorer${suffix ? `/${suffix}` : ''}`

const getPredictedScorers = async (client: ClientWithController, gameId: string) => await apiGet(client, endpoint(gameId))
const setPredictedScorers = async (client: ClientWithController, gameId: string, request: SetScorerRequest[]) =>
  await apiPut(client, endpoint(gameId), request)
const getParticipantPredictedScorers = async (client: ClientWithController, gameId: string) => await apiGet(client, endpoint(gameId, 'all'))

export { getPredictedScorers, setPredictedScorers, getParticipantPredictedScorers }
