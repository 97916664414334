import { RadioButtonChecked, RadioButtonUnchecked } from '@mui/icons-material'
import { Button, Divider, Paper, Stack, Typography } from '@mui/material'
import { TeamName } from 'components'
import { StageTeam, TeamIdentifier } from 'modules/api/types'
import React from 'react'

interface IProps {
  segment: StageTeam
  onSelect?: (team: TeamIdentifier) => void
  viewOnly: boolean
  disabledButtons: boolean
}

const KnockoutSegment: React.FC<IProps> = ({ segment, onSelect, viewOnly, disabledButtons }) => {
  const renderTeamButton = (placeholderKey: string) => {
    const team = segment.options[placeholderKey]
    const teamIsSelected = team && segment.team && team.id === segment.team.id
    return viewOnly ? (
      <Stack
        key={placeholderKey}
        direction='row'
        height='35px'
        px={1.5}
        sx={{
          background: (theme) => (teamIsSelected ? theme.palette.primary.main : theme.palette.background.paper),
          borderRadius: 1,
          color: (theme) =>
            !team
              ? theme.palette.emphasis
              : teamIsSelected
              ? theme.palette.getContrastText(theme.palette.primary.main)
              : theme.palette.getContrastText(theme.palette.background.paper),
        }}
      >
        <TeamName team={team} placeholder={placeholderKey} typographyProps={{ textTransform: 'uppercase' }} />
      </Stack>
    ) : (
      <Button
        key={placeholderKey}
        disabled={disabledButtons || !team}
        variant={teamIsSelected ? 'contained' : 'outlined'}
        sx={{
          justifyContent: 'space-between',
          backgroundColor: teamIsSelected ? 'primary' : 'background.paper',
          color: (theme) =>
            teamIsSelected
              ? theme.palette.getContrastText(theme.palette.primary.main)
              : theme.palette.getContrastText(theme.palette.background.paper),
        }}
        endIcon={teamIsSelected ? <RadioButtonChecked /> : <RadioButtonUnchecked />}
        onClick={() => !viewOnly && team && onSelect && onSelect(team)}
      >
        {<TeamName team={team} placeholder={placeholderKey} />}
      </Button>
    )
  }

  return (
    <Paper sx={{ p: 1, backgroundColor: 'focus' }}>
      <Stack gap={1}>
        <Typography textAlign='center' variant='subtitle1'>
          {segment.stage}
        </Typography>
        <Divider sx={{ mb: 1 }} />
        {Object.keys(segment.options).map((placeholderKey) => renderTeamButton(placeholderKey))}
      </Stack>
    </Paper>
  )
}

export default KnockoutSegment
