import { ArrowBack, ArrowForward } from '@mui/icons-material'
import { Alert, Box, Button, Container, Stack, Theme, useMediaQuery } from '@mui/material'
import { LoadingBox } from 'components'
import { usePredictorContext } from 'context/PredictorContext'
import { usePredictorApi } from 'modules/api'
import { getGameFixtures } from 'modules/api/requests'
import { Fixture as FixtureType, TournamentStage } from 'modules/api/types'
import { ActiveGamePageHeader, useActiveGame } from 'modules/games'
import { PREDICTOR_ROUTE_COLLECTION } from 'predictor-constants'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { getStageFromCode } from 'utils/stage-utils'
import { Fixture, FixturesFilter, SidebarFixtureDetails } from '../components'
import { FixturesFilter as FixturesFilterType, FixturesFilterGroupBy } from '../types'
import { getGroupsFromFixtures } from '../utils'

const Fixtures = () => {
  const uag = useActiveGame()
  const { game } = uag
  const isSmDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'))

  const { setRightSidebarOpen } = usePredictorContext()

  const apiGetGameFixtures = usePredictorApi<FixtureType[]>()
  const fixtures = apiGetGameFixtures.data

  const [fixturesFilter, setFixturesFilter] = useState<FixturesFilterType>()
  const [filteredFixtures, setFilteredFixtures] = useState<FixtureType[]>()
  const [selectedFixture, setSelectedFixture] = useState<FixtureType>()

  const onFixtureSelected = (fixture: FixtureType) => {
    setSelectedFixture(fixture)
    setRightSidebarOpen(true)
  }

  const executeFilter = (fixtureList?: FixtureType[], filter?: FixturesFilterType): FixtureType[] => {
    if (!fixtureList) return []

    if (!filter) return fixtureList

    if (filter.groupBy === FixturesFilterGroupBy.Group) {
      return fixtureList?.filter((x) => {
        const fixtureStage = getStageFromCode(x.code)
        return fixtureStage && fixtureStage.isGroupStage && fixtureStage.subStage === filter.group
      })
    }

    if (filter.groupBy === FixturesFilterGroupBy.Stage) {
      return fixtureList?.filter((x) => filter.stage === getStageFromCode(x.code)?.key)
    }

    return fixtureList
  }

  const updateAndExecuteFilter = (newFilter: FixturesFilterType) => {
    setFixturesFilter(newFilter)
    setFilteredFixtures(executeFilter(fixtures, newFilter))
  }

  useEffect(() => {
    if (!game) return
    apiGetGameFixtures.requestWithParams((client) => getGameFixtures(client, game?.id))
  }, [game])

  useEffect(() => {
    if (!game || !fixtures || fixtures.length === 0) return

    if (fixturesFilter) {
      // Fixtures filter existed already. Execute it on new data.
      setFilteredFixtures(executeFilter(fixtures, fixturesFilter))
      return
    }

    // Filter did not exist. Initialize and execute.
    if (game.predictionRules.teamPredictionsLocked || game.predictionRules.groupPredictionsAllowed) {
      let currentStage = getStageFromCode(fixtures[fixtures.length - 1].code) // Choose final stage by default.
      const nextFixtureToPlay = fixtures.find((x) => x.fixtureStatus === 'NotStarted')
      if (nextFixtureToPlay) currentStage = getStageFromCode(nextFixtureToPlay.code) // Choose next stage currently to play.
      if (!currentStage) return

      updateAndExecuteFilter({
        groupBy: FixturesFilterGroupBy.Stage,
        group: 'A',
        stage: TournamentStage[currentStage.key as keyof typeof TournamentStage],
      })
      return
    }

    updateAndExecuteFilter({
      groupBy: FixturesFilterGroupBy.Group,
      group: getStageFromCode(fixtures[0].code)?.subStage ?? '',
      stage: TournamentStage.group,
    })
  }, [fixtures])

  const onFilterChange = (newFilter: FixturesFilterType) => {
    setFixturesFilter(newFilter)
    setFilteredFixtures([])

    if (!game) return // Will not happen.

    apiGetGameFixtures.requestWithParams((client) => getGameFixtures(client, game?.id))
  }

  const renderNextPrevious = useCallback(() => {
    if (!fixtures || !fixturesFilter) return <></>

    // Only enabled for groups.
    if (fixturesFilter.groupBy !== FixturesFilterGroupBy.Group || !fixturesFilter.group) return <></>

    const groups = getGroupsFromFixtures(fixtures)

    const currGroupIndex = groups.indexOf(fixturesFilter.group)
    if (currGroupIndex < 0) return <></>

    const prevGroupIndex = currGroupIndex === 0 ? -1 : currGroupIndex - 1
    const nextGroupIndex = currGroupIndex === groups.length - 1 ? -1 : currGroupIndex + 1

    const renderStep = (groupIndex: number, isNext: boolean): React.ReactElement => (
      <Button
        startIcon={!isNext ? <ArrowBack /> : undefined}
        endIcon={isNext ? <ArrowForward /> : undefined}
        onClick={() => onFilterChange({ ...fixturesFilter, group: groups[groupIndex] })}
      >
        Group {groups[groupIndex]}
      </Button>
    )

    return (
      <Stack direction='row' justifyContent='center' gap={1}>
        {prevGroupIndex !== -1 && renderStep(prevGroupIndex, false)}
        {nextGroupIndex !== -1 && renderStep(nextGroupIndex, true)}
      </Stack>
    )
  }, [fixtures, fixturesFilter])

  const fixturesContainerRef = useRef<null | HTMLDivElement>(null)
  const scrollToFixture = useRef<null | HTMLDivElement>(null)

  const fixtureIdToScrollTo = () => {
    if (
      !filteredFixtures ||
      !fixturesFilter ||
      fixturesFilter.groupBy !== 'Stage' ||
      filteredFixtures.length === 0 ||
      filteredFixtures[0].dateTime > new Date()
    )
      return undefined

    const fixturesWithFullTime = filteredFixtures.filter((x) => x.fixtureStatus === 'FullTime')

    if (!fixturesWithFullTime || fixturesWithFullTime.length === 0) return undefined

    return fixturesWithFullTime[fixturesWithFullTime.length - 1].id
  }

  useEffect(() => {
    if (!scrollToFixture.current || !fixturesContainerRef?.current) return
    fixturesContainerRef.current.scrollTo(0, scrollToFixture.current.offsetTop - 200 - 110)
  }, [filteredFixtures])

  return (
    <Container maxWidth='md' disableGutters={isSmDown}>
      <ActiveGamePageHeader useActiveGameState={uag} route={PREDICTOR_ROUTE_COLLECTION.fixtures} />
      {game && fixtures && fixturesFilter && (
        <Stack gap={2}>
          {
            <FixturesFilter
              fixtures={fixtures}
              filter={fixturesFilter}
              onChange={onFilterChange}
              extendedPredictionsStatus={uag.extendedPredictionsStatus}
            />
          }
        </Stack>
      )}
      {fixturesFilter && fixturesFilter.groupBy === 'Stage' && fixturesFilter.stage !== TournamentStage.group && (
        <Alert severity='info'>
          Enter score predictions for 90 minutes + injury time only. Extra time <strong>is not</strong> included.
        </Alert>
      )}
      {apiGetGameFixtures.isLoading && <LoadingBox />}
      {game && filteredFixtures && filteredFixtures.length > 0 && !apiGetGameFixtures.isLoading && (
        <Stack gap={2} mt={2} sx={{ overflowY: 'auto', maxHeight: 'calc(100vh - 200px)' }} ref={fixturesContainerRef}>
          {filteredFixtures.map((fixture) => (
            <Box key={fixture.id} ref={fixtureIdToScrollTo() === fixture.id ? scrollToFixture : undefined}>
              <Fixture
                game={game}
                fixture={fixture}
                selected={Boolean(selectedFixture && selectedFixture.id === fixture.id)}
                onSelected={() => onFixtureSelected(fixture)}
              />
            </Box>
          ))}
          {renderNextPrevious()}
        </Stack>
      )}

      {selectedFixture && game && <SidebarFixtureDetails game={game} fixture={selectedFixture} />}
    </Container>
  )
}

export default Fixtures
