import { GetTokenSilentlyOptions } from '@auth0/auth0-react'
import axios, { AxiosInstance, AxiosRequestHeaders } from 'axios'
import { ClientWithController } from './types'
const { apiBaseUrl } = window.config

const getApiClient = async (
  getAccessTokenFn: (options?: GetTokenSilentlyOptions | undefined) => Promise<string>,
  activeParticipantId?: string,
): Promise<AxiosInstance> => {
  const token = await getAccessTokenFn({
    audience: 'https://api.predictor.unclebenportal.net',
  })

  const headers: AxiosRequestHeaders = { Authorization: `Bearer ${token}` }

  if (activeParticipantId) {
    headers['PredictorTestParticipant'] = activeParticipantId
  }

  return axios.create({
    baseURL: apiBaseUrl,
    headers: headers,
  })
}

const apiSend = async (client: ClientWithController, method: string, endpoint: string, data?: unknown) => {
  return await client.client.request({
    method,
    url: endpoint,
    data,
    signal: client.abortController.signal,
  })
}

const apiGet = async (client: ClientWithController, endpoint: string) => await apiSend(client, 'get', endpoint)
const apiPost = async (client: ClientWithController, endpoint: string, data?: unknown) => await apiSend(client, 'post', endpoint, data)
const apiPatch = async (client: ClientWithController, endpoint: string, data?: unknown) => await apiSend(client, 'patch', endpoint, data)
const apiPut = async (client: ClientWithController, endpoint: string, data?: unknown) => await apiSend(client, 'put', endpoint, data)
const apiDelete = async (client: ClientWithController, endpoint: string) => await apiSend(client, 'delete', endpoint)

export { getApiClient, apiGet, apiPost, apiPatch, apiPut, apiDelete }
