import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Theme,
  Tooltip,
  Typography,
  useMediaQuery,
} from '@mui/material'
import { GroupTeam } from 'modules/api/types'
import { TeamName } from 'components'
import React from 'react'
import { GroupTableRow as GroupTableRowType, GroupView } from '../types'

interface IProps {
  groupView: GroupView
  editablePositions: boolean
  disabledInputs: boolean
  onPositionSelectChange: (position: number, teamId: string) => void
}

const GroupTable: React.FC<IProps> = ({ groupView, editablePositions, disabledInputs, onPositionSelectChange }) => {
  const isSmDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'))
  const isMdDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'))

  const tableHead = (): { [name: string]: string } => {
    if (isSmDown)
      return {
        GP: 'Games Played',
        GD: 'Goal Difference',
        PTS: 'Points',
      }

    if (isMdDown)
      return {
        GP: 'Games Played',
        W: 'Wins',
        D: 'Draws',
        L: 'Losses',
        GD: 'Goal Difference',
        PTS: 'Points',
      }

    return {
      GP: 'Games Played',
      W: 'Wins',
      D: 'Draws',
      L: 'Losses',
      GF: 'Goals For',
      GA: 'Goals Against',
      GD: 'Goal Difference',
      PTS: 'Points',
    }
  }

  const getEmptyRow = (): GroupTableRowType => {
    const template: GroupTableRowType = {
      headers: tableHead(),
      cells: { GP: '', W: '', D: '', L: '', GF: '', GA: '', GD: '', PTS: '' },
    }

    if (isSmDown) {
      template.cells = { GP: '', GD: '', PTS: '' }
      return template
    }

    if (isMdDown) {
      template.cells = { GP: '', W: '', D: '', L: '', GD: '', PTS: '' }
      return template
    }

    return template
  }

  const setCellAmount = (row: GroupTableRowType, key: string, value: number | string) => {
    if (!Object.prototype.hasOwnProperty.call(row.cells, key)) return
    row.cells[key] = value
  }

  const tableRow = (team?: GroupTeam): GroupTableRowType => {
    const row = getEmptyRow()

    if (!team) return row

    row.team = team
    const gd = team.goalsFor - team.goalsAgainst
    const ptsMap: { [key: string]: number | string } = {
      GP: team.gamesPlayed,
      W: team.wins,
      D: team.draws,
      L: team.losses,
      GF: team.goalsFor,
      GA: team.goalsAgainst,
      GD: `${gd > 0 ? '+' : ''}${gd}`,
      PTS: team.points,
    }
    Object.keys(ptsMap).map((key) => setCellAmount(row, key, ptsMap[key]))

    return row
  }

  return (
    <Table sx={{ td: { px: 0.5, py: 1 } }}>
      <TableHead>
        <TableRow>
          <TableCell width='20px' align='center'>
            #
          </TableCell>
          <TableCell>Team</TableCell>
          {Object.keys(tableHead()).map((h) => (
            <TableCell width='20px' key={h}>
              <Tooltip title={tableHead()[h]}>
                <Box>{h}</Box>
              </Tooltip>
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {groupView.teams
          .map((team) => tableRow(team))
          .map((row, rowIndex) => (
            <TableRow key={row.team?.teamId ?? rowIndex}>
              <TableCell align='center' width='20px'>
                <Typography variant='body2'>{rowIndex + 1}</Typography>
              </TableCell>
              <TableCell>
                {editablePositions && groupView.positionOptions && groupView.teamPredictions && groupView.positionOptions[rowIndex + 1] && (
                  <FormControl size='small' sx={{ width: isSmDown ? '120px' : '250px' }}>
                    <InputLabel>Team</InputLabel>
                    <Select
                      disabled={disabledInputs}
                      size='small'
                      value={groupView.teamPredictions[rowIndex + 1] ?? ''}
                      onChange={(e) => onPositionSelectChange(rowIndex + 1, e.target.value)}
                      label='Team'
                    >
                      {groupView.positionOptions[rowIndex + 1]
                        .filter((x) => x !== undefined)
                        .map((option) => (
                          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                          <MenuItem key={option!.id} value={option!.id}>
                            <TeamName team={option} showFullName={!isSmDown} />
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                )}
                {!editablePositions && row && <TeamName team={row.team} />}
              </TableCell>
              {Object.keys(row.headers).map((h) => (
                <TableCell key={h} align='center'>
                  <Tooltip title={row.headers[h]}>
                    <Box sx={{ fontWeight: h === 'PTS' ? 'bold' : 'inherit' }}>{row.cells[h]}</Box>
                  </Tooltip>
                </TableCell>
              ))}
            </TableRow>
          ))}
      </TableBody>
    </Table>
  )
}

export default GroupTable
