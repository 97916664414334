import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { PREDICTOR_ROUTE_COLLECTION } from 'predictor-constants'
import { Typography } from '@mui/material'
import { usePredictorContext } from 'context/PredictorContext'

const LandingPage = () => {
  const { user } = usePredictorContext()
  const navigate = useNavigate()
  useEffect(() => {
    if (!user) return
    if (user.activeGameId)
      // navigate(generateRouteWithId(PREDICTOR_ROUTE_COLLECTION.gameDashboard, user.activeGameId))
      navigate(PREDICTOR_ROUTE_COLLECTION.fixtures.path)
    else navigate(PREDICTOR_ROUTE_COLLECTION.gameSelection.path)
  }, [user])

  return (
    <Typography variant='body1' p={3}>
      You are being redirected.
    </Typography>
  )
}

export default LandingPage
