import { Fixture, TournamentStage } from 'modules/api/types'
import { ExtendedPredictionsStatus } from 'modules/games'
import { getStageFromCode } from 'utils/stage-utils'

const getGroupsFromFixtures = (fixtures: Fixture[]) =>
  (
    [
      ...new Set(
        fixtures
          .map((x) => getStageFromCode(x.code))
          .filter((x) => x !== undefined && x.isGroupStage && Boolean(x.subStage))
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          .map((x) => x!.subStage),
      ),
    ] as string[]
  ).sort()

const isGroupMissingPredictions = (
  fixtures: Fixture[],
  extendedPredictionsStatus: ExtendedPredictionsStatus | undefined,
  group: 'any' | string,
): boolean => {
  if (!extendedPredictionsStatus) return false
  if (!extendedPredictionsStatus.isMissingUnlockedFixturePredictions) return false

  const groupFixtureIds = fixtures
    .map((x) => ({ id: x.id, stage: getStageFromCode(x.code) }))
    .filter((x) => x.stage !== undefined && x.stage.isGroupStage && (group === 'any' || x.stage.subStage === group))
    .map((x) => x.id)

  return extendedPredictionsStatus.incompleteUnlockedFixturePredictions.filter((x) => groupFixtureIds.includes(x)).length > 0
}

const isKnockoutStageMissingPredictions = (
  fixtures: Fixture[],
  extendedPredictionsStatus: ExtendedPredictionsStatus | undefined,
  stage: TournamentStage,
) => {
  if (!extendedPredictionsStatus) return false
  if (!extendedPredictionsStatus.isMissingUnlockedFixturePredictions) return false
  const stageFixtureIds = fixtures
    .map((x) => ({ id: x.id, stage: getStageFromCode(x.code) }))
    .filter((x) => x.stage !== undefined && x.stage.key === stage.toString())
    .map((x) => x.id)

  return extendedPredictionsStatus.incompleteUnlockedFixturePredictions.filter((x) => stageFixtureIds.includes(x)).length > 0
}

export { getGroupsFromFixtures, isGroupMissingPredictions, isKnockoutStageMissingPredictions }
