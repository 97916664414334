import { usePredictorContext } from 'context/PredictorContext'
import { usePredictorApi } from 'modules/api'
import { getGame } from 'modules/api/requests'
import { Game } from 'modules/api/types'
import { useEffect, useState } from 'react'
import { ExtendedPredictionsStatus } from '../types'
import { getExtendedPredictionsStatus } from '../utils'

export interface IUseActiveGameState {
  game: Game | undefined
  getGameIsLoading: boolean
  getGameError: string | undefined
  refreshGame: () => void
  extendedPredictionsStatus?: ExtendedPredictionsStatus
}

const useActiveGame = (): IUseActiveGameState => {
  const { user, participantStatus } = usePredictorContext()
  const apiGetGame = usePredictorApi<Game>()
  const [extendedPredictionsStatus, setExtendedPredictionsStatus] = useState<ExtendedPredictionsStatus>()

  useEffect(() => {
    if (!user) return
    if (!user.activeGameId) return

    apiGetGame.requestWithParams((client) => getGame(client, user.activeGameId as string))
  }, [user])

  useEffect(() => {
    if (!apiGetGame.data || !participantStatus) {
      setExtendedPredictionsStatus(undefined)
      return
    }
    setExtendedPredictionsStatus(getExtendedPredictionsStatus(participantStatus.predictionsStatus, apiGetGame.data))
  }, [apiGetGame.data, participantStatus])

  const refreshGame = () => {
    if (!user) return
    apiGetGame.requestWithParams((client) => getGame(client, user.activeGameId as string))
  }

  return {
    game: apiGetGame.data,
    getGameIsLoading: apiGetGame.isLoading,
    getGameError: apiGetGame.error,
    refreshGame,
    extendedPredictionsStatus,
  }
}

export default useActiveGame
