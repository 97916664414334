import { InfoRounded } from '@mui/icons-material'
import { FormControlLabel, Paper, Stack, Switch, TextField, Theme, Tooltip, Typography, useMediaQuery } from '@mui/material'
import { StepperButtons } from 'components'
import { GameConfigurationGamePoint } from 'modules/api/types'
import { NewGameStep3Value, StepProps } from '../types'
import GamePointsForm from './GamePointsForm'

const NewGameStep3: React.FC<StepProps<NewGameStep3Value | undefined>> = ({
  stepValue,
  onChange,
  onNext,
  onPrevious,
}: StepProps<NewGameStep3Value | undefined>) => {
  const isSmDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'))

  return (
    <>
      {stepValue && <GamePointsForm configurationPoints={stepValue?.points} onChange={(newPoints) => onChange({ points: newPoints })} />}

      <StepperButtons
        onPreviousClick={() => onPrevious && onPrevious()}
        onNextClick={() => onNext && onNext()}
        previousText={isSmDown ? 'Options' : 'Game Options'}
        nextText={isSmDown ? 'Create' : 'Create game'}
      />
    </>
  )
}

export default NewGameStep3
