import { ArrowCircleLeft, ArrowCircleRight } from '@mui/icons-material'
import { Button, Stack } from '@mui/material'
import React from 'react'

interface IProps {
  hidePrevious?: boolean
  hideNext?: boolean
  previousDisabled?: boolean
  nextDisabled?: boolean
  previousText?: string
  nextText?: string
  nextIsSubmit?: boolean
  onPreviousClick?: () => void
  onNextClick?: () => void
}

const StepperButtons: React.FC<IProps> = ({
  onPreviousClick,
  onNextClick,
  hidePrevious = false,
  hideNext = false,
  previousDisabled = false,
  nextDisabled = false,
  previousText = 'Previous',
  nextText = 'Next',
  nextIsSubmit = false,
}) => {
  return (
    <Stack direction='row' gap={1} justifyContent='center' mt={5}>
      {!hidePrevious && (
        <Button
          sx={{ flexBasis: 0, flexGrow: 1, maxWidth: '200px' }}
          variant='outlined'
          startIcon={<ArrowCircleLeft />}
          disabled={previousDisabled}
          onClick={() => onPreviousClick && onPreviousClick()}
        >
          {previousText}
        </Button>
      )}
      {!hideNext && (
        <Button
          sx={{ flexBasis: 0, flexGrow: 1, maxWidth: '200px' }}
          variant='contained'
          endIcon={<ArrowCircleRight />}
          disabled={nextDisabled}
          type={nextIsSubmit ? 'submit' : 'button'}
          onClick={() => onNextClick && onNextClick()}
        >
          {nextText}
        </Button>
      )}
    </Stack>
  )
}

export default StepperButtons
