import { apiGet, apiPut } from 'modules/api/predictor-api-client'
import { ClientWithController, SetFixturePredictionRequest } from '../types'

const endpoint = (gameId: string, fixtureId: string, suffix?: string) =>
  `/game/${gameId}/prediction/fixture/${fixtureId}${suffix ? `/${suffix}` : ''}`

const getFixturePrediction = async (client: ClientWithController, gameId: string, fixtureId: string) =>
  await apiGet(client, endpoint(gameId, fixtureId))
const setFixturePrediction = async (
  client: ClientWithController,
  gameId: string,
  fixtureId: string,
  request: SetFixturePredictionRequest,
) => await apiPut(client, endpoint(gameId, fixtureId), request)
const getAllFixturePredictions = async (client: ClientWithController, gameId: string, fixtureId: string) =>
  await apiGet(client, endpoint(gameId, fixtureId, 'all'))

export { getFixturePrediction, setFixturePrediction, getAllFixturePredictions }
