import {
  AccountTree,
  AddBox,
  ArrowRight,
  Edit,
  FormatListNumbered,
  Group,
  Label,
  Scoreboard,
  SportsScore,
  SportsSoccer,
  TableRows,
  ViewHeadline,
} from '@mui/icons-material'
import { Fixtures } from 'modules/fixtures'
import { GameSelection, GameInvite, GameDashboard, NewGame, GameParticipants, ChangeGameAlias, EditGamePoints } from 'modules/games'
import { Groups } from 'modules/groups'
import { Knockout } from 'modules/knockout'
import { Rules } from 'modules/rules'
import { Scorers } from 'modules/scorers'
import { Standings } from 'modules/standings'
import { Callback } from 'modules/users'
import { LandingPage } from 'pages'
import { Route, Routes, Location } from 'react-router-dom'
import { APP_DETAILS } from '.'
import { ModalLocationState, PredictorRouteCollection } from '../types/routing'

const PREDICTOR_ROUTE_COLLECTION: PredictorRouteCollection = {
  home: {
    path: '/',
    title: APP_DETAILS.TITLE,
    icon: ArrowRight,
  },
  gameSelection: {
    path: '/games',
    title: 'Games',
    icon: SportsScore,
  },
  gameInvite: {
    path: '/games/invite/:{0}',
    pathForRoute: '/games/invite/:gameId',
    title: 'Game Invite',
    icon: SportsScore,
  },
  gameDashboard: {
    path: '/game/:{0}',
    pathForRoute: '/game/:gameId',
    title: 'Game Dashboard',
    icon: SportsScore,
  },
  newGame: {
    path: '/game/new',
    title: 'New Game',
    icon: AddBox,
  },
  editGamePoints: {
    path: '/game/:{0}/editPoints',
    pathForRoute: '/game/:gameId/editPoints',
    title: 'Edit Game Points',
    icon: Edit,
  },
  gameParticipants: {
    path: '/game/:{0}/participants',
    pathForRoute: '/game/:gameId/participants',
    title: 'Game Participants',
    icon: Group,
  },
  standings: {
    path: '/standings',
    title: 'Standings',
    icon: FormatListNumbered,
  },
  fixtures: {
    path: '/fixtures',
    title: 'Fixtures',
    icon: Scoreboard,
  },
  groups: {
    path: '/groups',
    title: 'Groups',
    icon: TableRows,
  },
  knockout: {
    path: '/knockout',
    title: 'Knockout',
    icon: AccountTree,
  },
  scorers: {
    path: '/scorers',
    title: 'Scorers',
    icon: SportsSoccer,
  },
  rules: {
    path: '/rules',
    title: 'Rules',
    icon: ViewHeadline,
  },
  changeGameAlias: {
    path: '/gameAlias',
    title: 'Change name',
    icon: Label,
  },
}

export const setupRoutes = (location: Location) => {
  const modalLocationState = location.state as ModalLocationState | undefined
  return (
    <>
      <Routes location={modalLocationState?.backgroundLocation || location}>
        <Route path={PREDICTOR_ROUTE_COLLECTION.home.path}>
          <Route index element={<LandingPage />} />
          <Route path='callback' element={<Callback />} />
          <Route path={PREDICTOR_ROUTE_COLLECTION.gameSelection.path} element={<GameSelection />} />
          <Route path={PREDICTOR_ROUTE_COLLECTION.gameInvite.pathForRoute} element={<GameInvite />} />
          <Route path={PREDICTOR_ROUTE_COLLECTION.gameDashboard.pathForRoute} element={<GameDashboard />} />
          <Route path={PREDICTOR_ROUTE_COLLECTION.newGame.path} element={<NewGame />} />
          <Route path={PREDICTOR_ROUTE_COLLECTION.editGamePoints.pathForRoute} element={<EditGamePoints />} />
          <Route path={PREDICTOR_ROUTE_COLLECTION.gameParticipants.pathForRoute} element={<GameParticipants />} />
          <Route path={PREDICTOR_ROUTE_COLLECTION.standings.path} element={<Standings />} />
          <Route path={PREDICTOR_ROUTE_COLLECTION.fixtures.path} element={<Fixtures />} />
          <Route path={PREDICTOR_ROUTE_COLLECTION.groups.path} element={<Groups />} />
          <Route path={PREDICTOR_ROUTE_COLLECTION.knockout.path} element={<Knockout />} />
          <Route path={PREDICTOR_ROUTE_COLLECTION.scorers.path} element={<Scorers />} />
          <Route path={PREDICTOR_ROUTE_COLLECTION.rules.path} element={<Rules />} />
          <Route path={PREDICTOR_ROUTE_COLLECTION.changeGameAlias.path} element={<ChangeGameAlias />} />
        </Route>
      </Routes>

      {/* {modalLocationState?.backgroundLocation && (
        <Routes>
          <Route path='/fixture/:fixtureId' element={<FixtureDetailsModal fixture={modalLocationState.props as Fixture} />} />
        </Routes>
      )} */}
    </>
  )
}

export default PREDICTOR_ROUTE_COLLECTION
