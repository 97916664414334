import { Alert, Hidden } from '@mui/material'
import { LoadingBox, PageTitle } from 'components'
import { usePredictorContext } from 'context/PredictorContext'
import { PREDICTOR_ROUTE_COLLECTION } from 'predictor-constants'
import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { PredictorRoute } from 'types/routing'
import { IUseActiveGameState } from '../hooks/useActiveGame'

interface IProps {
  useActiveGameState: IUseActiveGameState
  route: PredictorRoute
}

const ActiveGamePageHeader: React.FC<IProps> = ({ useActiveGameState, route }) => {
  const { user } = usePredictorContext()
  const { game, getGameIsLoading, getGameError } = useActiveGameState
  const navigate = useNavigate()

  useEffect(() => {
    if (game?.userParticipant.isDisabled) navigate(PREDICTOR_ROUTE_COLLECTION.gameSelection.path)
  }, [game])

  return (
    <>
      {game && (
        <PageTitle
          title={
            <>
              {user?.gameParticipants && user.gameParticipants.length > 1 && (
                <>
                  {game.name}
                  <Hidden smDown> - {route.title}</Hidden>
                </>
              )}
              {user?.gameParticipants && user.gameParticipants.length === 1 && route.title}
            </>
          }
          icon={route.icon}
        />
      )}
      {getGameIsLoading && <LoadingBox />}
      {getGameError && <Alert severity='error'>Could not get active game information.</Alert>}
      {game && game.userParticipant.isDisabled && (
        <Alert severity='error'>The game could not be loaded. Please contact your inviter.</Alert>
      )}
    </>
  )
}

export default ActiveGamePageHeader
