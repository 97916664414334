import { Container } from '@mui/material'
import { PageNotFound, PageTitle } from 'components'
import { PREDICTOR_ROUTE_COLLECTION } from 'predictor-constants'
import { useParams } from 'react-router-dom'

const GameDashboard = () => {
  const { gameId } = useParams()

  if (!gameId) return <PageNotFound />

  return (
    <Container maxWidth='lg'>
      <PageTitle title={PREDICTOR_ROUTE_COLLECTION.gameDashboard.title} icon={PREDICTOR_ROUTE_COLLECTION.gameDashboard.icon} />

      {gameId}
    </Container>
  )
}

export default GameDashboard
