import { apiDelete, apiGet, apiPatch, apiPost, apiPut } from 'modules/api/predictor-api-client'
import { ClientWithController, GameConfigurationGamePoint, GameInsertRequest } from '../types'

const endpointBase = '/game'
const endpoint = (gameId?: string, suffix?: string) => `${endpointBase}${gameId ? `/${gameId}` : ''}${suffix ? `/${suffix}` : ''}`

const getGameStandings = async (client: ClientWithController, gameId: string) => await apiGet(client, endpoint(gameId, 'standings'))
const joinGame = async (client: ClientWithController, gameId: string) => await apiPost(client, endpoint(gameId, 'participant'))
const createGame = async (client: ClientWithController, request: GameInsertRequest) => await apiPost(client, endpoint(), request)
const deleteGame = async (client: ClientWithController, gameId: string) => await apiDelete(client, endpoint(gameId))
const updateGamePointDefinitions = async (client: ClientWithController, gameId: string, newPoints: GameConfigurationGamePoint[]) =>
  await apiPut(
    client,
    endpoint(gameId, 'pointDefinition'),
    newPoints.map((x) => ({ ...x, pointType: x.code })),
  )
const getAdminGames = async (client: ClientWithController) => await apiGet(client, endpoint('admin'))
const getGameParticipants = async (client: ClientWithController, gameId: string) => await apiGet(client, endpoint(gameId, 'participant'))
const getGameParticipantsBasic = async (client: ClientWithController, gameId: string) =>
  await apiGet(client, endpoint(gameId, 'participants/basic'))
const getGame = async (client: ClientWithController, gameId: string) => await apiGet(client, endpoint(gameId))
const getGameFixtures = async (client: ClientWithController, gameId: string, stage?: string, teamCode?: string) => {
  let url = endpoint(gameId, 'fixture')
  if (stage || teamCode) {
    url += '?'
    if (stage) url += `stage=${stage}&`
    if (teamCode) url += `teamCode=${teamCode}&`
    url = url.substring(0, url.length - 1)
  }
  return await apiGet(client, url)
}
const patchGameAlias = async (client: ClientWithController, gameId: string, newAlias: string) =>
  await apiPatch(client, endpoint(gameId, 'alias'), { alias: newAlias })

const getCurrentParticipantStatus = async (client: ClientWithController, gameId: string) =>
  await apiGet(client, endpoint(gameId, 'participant/status'))

export {
  getGameStandings,
  joinGame,
  createGame,
  deleteGame,
  updateGamePointDefinitions,
  getAdminGames,
  getGameParticipants,
  getGame,
  getGameFixtures,
  getGameParticipantsBasic,
  patchGameAlias,
  getCurrentParticipantStatus,
}
