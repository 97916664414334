import {
  Alert,
  AlertTitle,
  Container,
  Hidden,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Theme,
  Typography,
  useMediaQuery,
} from '@mui/material'
import { ParticipantViewSelect, TeamName } from 'components'
import { usePredictorApi } from 'modules/api'
import { getParticipantPredictedScorers } from 'modules/api/requests'
import { Game, ParticipantPredictedScorers } from 'modules/api/types'
import { ActiveGamePageHeader, useActiveGame } from 'modules/games'
import { PREDICTOR_ROUTE_COLLECTION } from 'predictor-constants'
import { useEffect, useState } from 'react'
import { ScorersForm } from '../components'

const Scorers = () => {
  const uag = useActiveGame()
  const { game: activeGame } = uag

  const getParticipantScorersApi = usePredictorApi<ParticipantPredictedScorers[]>()
  const participantScorers = getParticipantScorersApi.data?.sort((a, b) => a.participant.gameAlias.localeCompare(b.participant.gameAlias))

  const [viewParticipant, setViewParticipant] = useState<string>()

  const isSmDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'))

  useEffect(() => {
    if (!activeGame) return

    if (activeGame.predictionRules.scorerPredictionsLocked) {
      getParticipantScorersApi.requestWithParams((client) => getParticipantPredictedScorers(client, activeGame.id))
    }

    if (!viewParticipant) setViewParticipant(activeGame.userParticipant.id)
  }, [activeGame])

  const unlockedScorersPage = (game: Game): React.ReactElement => (
    <>
      <Stack mt={1} mb={3} gap={1}>
        <Alert severity='info'>
          <AlertTitle>Select scorers</AlertTitle>
          <Stack gap={1}>
            <Stack direction='row' gap={1}>
              <Typography variant='body2'>
                Select <strong> {game?.predictionRules?.maxNumberOfScorers} players </strong> who you think will score the most in this
                tournament.
              </Typography>
            </Stack>
            <Stack direction='row' gap={1}>
              <Typography variant='body2'>Submit your selections before the start of the tournament.</Typography>
            </Stack>
          </Stack>
        </Alert>
        <ScorersForm game={game} />
      </Stack>
    </>
  )

  const renderParticipantScorers = (): React.ReactElement => {
    if (!participantScorers) return <></>

    const scorers = participantScorers.find((x) => x.participant.id === viewParticipant)?.scorers ?? []

    return (
      <Stack gap={1}>
        <Table size={isSmDown ? 'small' : 'medium'}>
          <TableHead>
            <TableRow>
              <Hidden smDown>
                <TableCell>Team</TableCell>
              </Hidden>
              <TableCell>Scorer</TableCell>
              <TableCell align='right'>Normal Goals</TableCell>
              <TableCell align='right'>Penalty Goals</TableCell>
              <TableCell align='right'>Points</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {scorers.map((scorer) => (
              <TableRow key={scorer.scorerName}>
                <Hidden smDown>
                  <TableCell>
                    <TeamName team={scorer.team} />
                  </TableCell>
                  <TableCell>{scorer.scorerName}</TableCell>
                </Hidden>
                <Hidden smUp>
                  <TableCell>
                    <Stack direction='row' alignItems='center' gap={1}>
                      <Hidden smUp>
                        <TeamName team={scorer.team} hideName />
                      </Hidden>
                      <span>{scorer.scorerName}</span>
                    </Stack>
                  </TableCell>
                </Hidden>
                <TableCell align='right'>
                  {scorer.normalGoals} <br /> <Typography variant='caption'>{scorer.normalGoalPoints} pts</Typography>
                </TableCell>
                <TableCell align='right'>
                  {scorer.penaltyGoals} <br /> <Typography variant='caption'>{scorer.penaltyGoalPoints} pts</Typography>
                </TableCell>
                <TableCell align='right'>
                  <strong>{scorer.totalPoints}</strong>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Stack>
    )
  }

  const lockedScorersPage = (game: Game): React.ReactElement => (
    <>
      {activeGame && viewParticipant && participantScorers && (
        <Stack gap={1} alignItems='center'>
          <ParticipantViewSelect
            game={game}
            includeRealOption={false}
            participants={participantScorers.map((x) => x.participant)}
            selectedView={viewParticipant}
            onChange={setViewParticipant}
          />
          <Stack gap={1}></Stack>
          {renderParticipantScorers()}
        </Stack>
      )}
    </>
  )

  return (
    <Container maxWidth='md' disableGutters={isSmDown}>
      <ActiveGamePageHeader useActiveGameState={uag} route={PREDICTOR_ROUTE_COLLECTION.scorers} />
      {activeGame && (
        <>
          {activeGame.predictionRules && !activeGame.predictionRules.scorerPredictionsLocked && unlockedScorersPage(activeGame)}
          {activeGame.predictionRules.scorerPredictionsLocked && lockedScorersPage(activeGame)}
        </>
      )}
    </Container>
  )
}

export default Scorers
