import { apiPatch } from 'modules/api/predictor-api-client'
import { ClientWithController, GameParticipantPatchRequest } from '../types'

const endpointBase = '/participant'
const endpoint = (participantId?: string, suffix?: string) =>
  `${endpointBase}${participantId ? `/${participantId}` : ''}${suffix ? `/${suffix}` : ''}`

const patchParticipant = async (client: ClientWithController, participantId: string, request: GameParticipantPatchRequest) =>
  await apiPatch(client, endpoint(participantId), request)

export { patchParticipant }
