import { Calculate } from '@mui/icons-material'
import { Button, Paper, Stack, Tooltip, Typography } from '@mui/material'
import { LoadingBox } from 'components'
import { usePredictorApi } from 'modules/api'
import { getTeamPredictionsForStage, setTeamPrediction } from 'modules/api/requests'
import { Game, GroupTeam, StageTeam, TeamIdentifier } from 'modules/api/types'
import { useSnackbar } from 'notistack'
import React, { useEffect, useState } from 'react'
import { isSuccessStatusCode } from 'utils/request-utils'
import { getGroupStageCode, getGroupStagePositionCode } from 'utils/stage-utils'
import { GroupView } from '../types'
import GroupTable from './GroupTable'

interface IProps {
  game: Game
  group: GroupView
  calculatedGroupPrediction?: GroupView
  showRealResults: boolean
}

const Group: React.FC<IProps> = ({ game, group, calculatedGroupPrediction, showRealResults }) => {
  const { enqueueSnackbar } = useSnackbar()

  const apiGetTeamPredictions = usePredictorApi<StageTeam[]>({
    apiFunc: (client) => getTeamPredictionsForStage(client, game.id, getGroupStageCode(group.groupName)),
  })
  const teamPredictionsSet = apiGetTeamPredictions.data

  const apiSetTeamPrediction = usePredictorApi({ errorMessage: 'Could not update team position.' })

  const onTeamPositionChange = async (position: number, teamId: string) => {
    const newCode = getGroupStagePositionCode(group.groupName, position)

    if (
      !isSuccessStatusCode(
        await apiSetTeamPrediction.requestWithParams((client) =>
          setTeamPrediction(client, game.id, teamId, { knockoutProgression: newCode }),
        ),
      )
    )
      return

    await apiGetTeamPredictions.request()
  }

  const [shownGroupView, setShownGroupView] = useState(group)

  useEffect(() => {
    if (!group || !calculatedGroupPrediction) return

    if (showRealResults) {
      // Show actual group positions
      setShownGroupView(group)
      return
    }

    if (game.predictionRules.teamPredictionsLocked || !game.predictionRules.groupPredictionsAllowed) {
      // Show calculated positions from fixture predictions. It could be from other participants.
      setShownGroupView(calculatedGroupPrediction)
      return
    }

    // Show predicted group positions after updating with setTeamPredictions.
    if (!teamPredictionsSet) {
      setShownGroupView({ groupName: group.groupName, teams: [] })
      return
    }

    const posPredSet: { [position: number]: string } = {}
    const posPredOptions: { [position: number]: (TeamIdentifier | undefined)[] } = {}
    const teamsWithTeamPreds: (GroupTeam | undefined)[] = []

    for (let i = 1; i <= calculatedGroupPrediction.teams.length; i++) {
      const positionPred = teamPredictionsSet.find((x) => x.stage === getGroupStagePositionCode(group.groupName, i))
      if (!positionPred) continue

      posPredSet[i] = positionPred.team?.id
      if (positionPred.team) teamsWithTeamPreds.push(calculatedGroupPrediction.teams.find((t) => t && t.teamId === positionPred.team.id))
      else teamsWithTeamPreds.push(undefined)
      posPredOptions[i] = Object.keys(positionPred.options).map((stage) => positionPred.options[stage])
    }

    const newView: GroupView = {
      ...calculatedGroupPrediction,
      teams: teamsWithTeamPreds,
      teamPredictions: posPredSet,
      positionOptions: posPredOptions,
    }

    setShownGroupView(newView)
  }, [group, calculatedGroupPrediction, teamPredictionsSet, showRealResults])

  const autoFillPositions = async () => {
    if (!calculatedGroupPrediction) return

    for (let i = 0; i < calculatedGroupPrediction.teams.length; i++) {
      const team = calculatedGroupPrediction.teams[i]

      if (!team) return false
      const res = await apiSetTeamPrediction.requestWithParams((client) =>
        setTeamPrediction(client, game.id, team?.teamId, { knockoutProgression: getGroupStagePositionCode(group.groupName, i + 1) }),
      )
      if (!isSuccessStatusCode(res)) return false
    }

    enqueueSnackbar('Predictions updated.', {
      variant: 'success',
    })
    apiGetTeamPredictions.request()
  }

  const isPredictionsLoading = () => apiSetTeamPrediction.isLoading || apiGetTeamPredictions.isLoading

  return (
    <Paper square>
      <Stack>
        <Stack
          direction='row'
          justifyContent='space-between'
          alignItems='center'
          py={1}
          px={2}
          sx={{ boxShadow: 2, backgroundColor: 'emphasis' }}
        >
          <Stack direction='row' alignItems='center' gap={1}>
            <Typography variant='h6'>Group {group.groupName}</Typography>
            {isPredictionsLoading() && <LoadingBox size='small' />}
          </Stack>
          {!game.predictionRules.teamPredictionsLocked && game.predictionRules.groupPredictionsAllowed && !isPredictionsLoading() && (
            <Tooltip title='Fill the positions according to the results set in fixture predictions.'>
              <Button variant='outlined' size='small' endIcon={<Calculate />} onClick={autoFillPositions}>
                Auto fill
              </Button>
            </Tooltip>
          )}
        </Stack>
        {!teamPredictionsSet && apiGetTeamPredictions.isLoading && <LoadingBox />}
        {teamPredictionsSet && calculatedGroupPrediction && (
          <GroupTable
            groupView={shownGroupView}
            editablePositions={!game.predictionRules.teamPredictionsLocked && game.predictionRules.groupPredictionsAllowed}
            disabledInputs={isPredictionsLoading()}
            onPositionSelectChange={onTeamPositionChange}
          />
        )}
      </Stack>
    </Paper>
  )
}

export default Group
