import { TabContext, TabList, TabPanel } from '@mui/lab'
import { Box, Container, Tab } from '@mui/material'
import { usePredictorApi } from 'modules/api'
import { getTournamentGameConfigurationOptions } from 'modules/api/requests'
import { GameConfigurationGamePoint, GameConfigurationOptions } from 'modules/api/types'
import { ActiveGamePageHeader, useActiveGame } from 'modules/games'
import { PREDICTOR_ROUTE_COLLECTION } from 'predictor-constants'
import { useEffect, useState } from 'react'
import { RuleList, RulesPoints } from '../components'

const Rules = () => {
  const uag = useActiveGame()
  const { game } = uag

  const apiGetGameConfigurationOptions = usePredictorApi<GameConfigurationOptions>()
  const tournamentGameConfig = apiGetGameConfigurationOptions.data

  const [gamePoints, setGamePoints] = useState<GameConfigurationGamePoint[]>([])

  useEffect(() => {
    if (!game) return
    apiGetGameConfigurationOptions.requestWithParams((client) => getTournamentGameConfigurationOptions(client, game.tournamentId))
  }, [game])

  useEffect(() => {
    if (!tournamentGameConfig || !game) return

    const newGamePoints = tournamentGameConfig.gamePoints
      .map((x) => {
        const pointDef = game.pointDefinitions.find((p) => p.pointType === x.code)
        return { ...x, pointValue: pointDef?.pointValue ?? 0, bonusForPointLeader: pointDef?.bonusForPointLeader ?? 0 }
      })
      .filter((x) => x.isEnabled)

    setGamePoints(newGamePoints)
  }, [tournamentGameConfig])

  const [activeTab, setActiveTab] = useState<string>('rules')

  return (
    <Container maxWidth='md' disableGutters>
      <ActiveGamePageHeader useActiveGameState={uag} route={PREDICTOR_ROUTE_COLLECTION.rules} />
      <TabContext value={activeTab}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <TabList onChange={(e, val) => setActiveTab(val)} centered variant='fullWidth'>
            <Tab label='Rules' value='rules' />
            <Tab label='Points' value='points' />
          </TabList>
        </Box>
        <TabPanel value='rules'>{game && <RuleList game={game} />}</TabPanel>
        <TabPanel value='points'>{gamePoints && <RulesPoints points={gamePoints} />}</TabPanel>
      </TabContext>
    </Container>
  )
}

export default Rules
