import { AppBar, Badge, Divider, Hidden, IconButton, Stack, Toolbar, Tooltip, Typography } from '@mui/material'
import React from 'react'
import { UserAvatar } from 'components'
import { usePredictorContext } from 'context/PredictorContext'
import MainMenu from '../main-menu/MainMenu'
import { DISPLAY_DATE_FORMAT, STYLE_SETTINGS } from 'predictor-constants'
import RightSidebar from '../right-sidebar/RightSidebar'
import ThemeControl from './ThemeControl'
import { useActiveGame } from 'modules/games'
import { format as formatDate } from 'date-fns'
import { InfoOutlined, WarningRounded } from '@mui/icons-material'

const Header: React.FC = () => {
  const { user, headerTitleContainerRef } = usePredictorContext()
  const { game: activeGame, extendedPredictionsStatus } = useActiveGame()

  const appBarSideActions = (
    <Stack direction='row' alignItems='center' gap={1} mr={1}>
      <ThemeControl />
      <Divider orientation='vertical' flexItem />
      {user && <UserAvatar size={30} user={user} />}
    </Stack>
  )

  const appBar = (
    <Stack
      direction='row'
      justifyContent='space-between'
      alignItems='center'
      width='100%'
      sx={{ mr: { xl: `${STYLE_SETTINGS.RIGHT_SIDEBAR_WIDTH}px` } }}
    >
      <Stack direction='row' flexGrow={1} gap={2} sx={{ pl: { lg: `calc(${STYLE_SETTINGS.DRAWER_WIDTH}px + 2rem)` } }} alignItems='center'>
        <Stack direction='row' ref={headerTitleContainerRef}>
          {/* Title */}
        </Stack>
        {activeGame && activeGame.predictionRules && !activeGame.predictionRules.scorerPredictionsLocked && (
          <Hidden mdDown>
            <Divider orientation='vertical' flexItem />
            <Stack direction='row' alignItems='center' gap={1}>
              <InfoOutlined fontSize='small' color='secondary' />
              <Typography variant='body2' color='secondary'>
                {activeGame.tournament.name} starts on {formatDate(new Date(activeGame.tournament.startDate), DISPLAY_DATE_FORMAT)}
              </Typography>
            </Stack>
          </Hidden>
        )}
      </Stack>
      {appBarSideActions}
    </Stack>
  )

  const smMenuButton = (
    <IconButton size='large' edge='start' color='inherit' aria-label='menu' sx={{ mx: 1 }}>
      <MainMenu />
    </IconButton>
  )

  const mainMenuToggle = (
    <>
      <Hidden lgDown>
        <MainMenu />
      </Hidden>
      <Hidden lgUp>
        {extendedPredictionsStatus?.isMissingUnlockedPredictions && (
          <Tooltip title='You have missing predictions. Check out the menu to fill out the predictions.'>
            <Badge
              anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
              badgeContent={<WarningRounded color='warning' sx={{ fontSize: 20, mt: '45px', ml: '-25px' }} />}
            >
              {smMenuButton}
            </Badge>
          </Tooltip>
        )}
        {!extendedPredictionsStatus?.isMissingUnlockedPredictions && smMenuButton}
      </Hidden>
    </>
  )

  const rightSidebarToggle = (
    <>
      <Hidden xlDown>
        <RightSidebar />
      </Hidden>
      <Hidden xlUp>
        <Divider flexItem orientation='vertical' sx={{ my: 1 }} />
        <IconButton size='large' edge='end' color='inherit'>
          <RightSidebar />
        </IconButton>
      </Hidden>
    </>
  )

  return (
    <AppBar
      position='fixed'
      sx={{
        zIndex: (theme) => theme.zIndex.drawer + 1,
      }}
    >
      <Toolbar variant='dense' disableGutters>
        {mainMenuToggle}
        {appBar}
        {rightSidebarToggle}
      </Toolbar>
    </AppBar>
  )
}

export default Header
