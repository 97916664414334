import { CircularProgress, Stack, SxProps, Theme } from '@mui/material'
import React from 'react'

interface IProps {
  size?: 'small' | 'standard'
}

const LoadingBox: React.FC<IProps> = ({ size = 'standard' }) => {
  const sx: SxProps<Theme> = {
    color: (theme: Theme) => theme.palette.primary.main,
    flexGrow: 1,
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    p: size === 'standard' ? 2 : 0,
  }

  return (
    <Stack sx={sx}>
      <CircularProgress size={size === 'standard' ? '3rem' : '20px'} color='inherit' />
    </Stack>
  )
}

export default LoadingBox
