import { Avatar, FormControl, InputLabel, MenuItem, Select, Stack, Typography } from '@mui/material'
import { UserAvatar } from 'components'
import { usePredictorContext } from 'context/PredictorContext'
import { usePredictorApi } from 'modules/api'
import { getGameParticipantsBasic } from 'modules/api/requests'
import { Game, ParticipantBasicDetails } from 'modules/api/types'
import React, { useEffect } from 'react'

export type viewParticipantType = 'actual' | string | undefined

interface IProps {
  game: Game
  participants?: ParticipantBasicDetails[]
  includeRealOption: boolean
  selectedView: viewParticipantType
  onChange: (newView: viewParticipantType) => void
}

const ParticipantViewSelect: React.FC<IProps> = ({ game, participants, includeRealOption, selectedView, onChange }: IProps) => {
  const { user } = usePredictorContext()

  const apiGetParticipantsBasic = usePredictorApi<ParticipantBasicDetails[]>()
  const apiParticipants = apiGetParticipantsBasic.data?.sort((a, b) => a.gameAlias.localeCompare(b.gameAlias))

  useEffect(() => {
    if (!game || Boolean(participants)) return

    apiGetParticipantsBasic.requestWithParams((client) => getGameParticipantsBasic(client, game.id))
  }, [game])

  const renderOptionContent = (icon: React.ReactElement, text: string, isActiveParticipant: boolean): React.ReactElement => (
    <Stack direction='row' alignItems='center' gap={1}>
      {icon}
      <Typography variant='body2' color={isActiveParticipant ? 'primary' : undefined}>
        {text}
      </Typography>
    </Stack>
  )

  return (
    <FormControl sx={{ minWidth: '100px' }}>
      <InputLabel>View</InputLabel>
      <Select label='View' value={selectedView} onChange={(e) => onChange(e.target.value)}>
        {includeRealOption && (
          <MenuItem value='actual'>
            {renderOptionContent(<Avatar sx={{ width: 30, height: 30 }}>R</Avatar>, 'Real Results', false)}
          </MenuItem>
        )}
        <MenuItem value={game.userParticipant.id}>
          <Stack direction='row' alignItems='center' gap={1}>
            {renderOptionContent(
              <UserAvatar
                size={30}
                user={{
                  name: user?.name ?? game.userParticipant.gameAlias,
                  picture: user?.picture,
                }}
              />,
              game.userParticipant.gameAlias,
              true,
            )}
          </Stack>
        </MenuItem>
        {participants &&
          participants
            .filter((x) => x.id !== game.userParticipant.id)
            .map((p) => (
              <MenuItem key={p.id} value={p.id}>
                {renderOptionContent(
                  <UserAvatar size={30} user={{ name: p.userName ?? p.gameAlias, picture: p.picture }} />,
                  p.gameAlias,
                  false,
                )}
              </MenuItem>
            ))}

        {apiParticipants &&
          apiParticipants
            .filter((x) => x.id !== game.userParticipant.id)
            .map((p) => (
              <MenuItem key={p.id} value={p.id}>
                {renderOptionContent(
                  <UserAvatar size={30} user={{ name: p.userName ?? p.gameAlias, picture: p.picture }} />,
                  p.gameAlias,
                  false,
                )}
              </MenuItem>
            ))}
      </Select>
    </FormControl>
  )
}

export default ParticipantViewSelect
