import { ReactElement } from 'react'
import './App.css'
import { useAuth0 } from '@auth0/auth0-react'
import { Box, Container, CssBaseline, Stack, ThemeProvider } from '@mui/material'
import { PREDICTOR_THEME, PREDICTOR_THEME_DARK, STYLE_SETTINGS } from 'predictor-constants'
import { useLocation } from 'react-router-dom'
import { usePredictorContext } from 'context/PredictorContext'
import { Login } from 'modules/users'
import { Footer, Header } from 'modules/layout'
import { LoadingBackdrop } from 'components'
import { ModalContextProvider, ConfirmModalContextProvider, ItemMenuContextProvider } from 'modules/ui'
import { setupRoutes } from 'predictor-constants/predictor-route-collection'

function App() {
  const { isLoading: isAuthLoading, isAuthenticated } = useAuth0()
  const { user, userError, themeMode, mainContainerRef } = usePredictorContext()
  const location = useLocation()

  const wrapInTheme = (element: ReactElement) => (
    <ThemeProvider theme={themeMode === 'dark' ? PREDICTOR_THEME_DARK : PREDICTOR_THEME}>{element}</ThemeProvider>
  )

  if ((!isAuthenticated && !isAuthLoading) || userError !== undefined) {
    return wrapInTheme(<Login userError={userError} />)
  }

  if (user === undefined) {
    return wrapInTheme(<LoadingBackdrop hideBackground />)
  }

  const body = (
    <Box
      sx={(theme) => ({
        display: 'flex',
        flexDirection: 'column',
        ml: { lg: `${STYLE_SETTINGS.DRAWER_WIDTH}px` },
        mr: { xl: `${STYLE_SETTINGS.RIGHT_SIDEBAR_WIDTH}px` },
        background: theme.palette.background.default,
        height: '100vh',
        overflow: 'hidden',
      })}
    >
      <CssBaseline enableColorScheme />
      <Header />
      <Stack height='100vh' sx={{ overflowY: 'scroll' }} ref={mainContainerRef}>
        <Container
          component='main'
          sx={(theme) => ({
            mt: 'calc(48px + 1rem)',
            mb: 2,
            color: theme.palette.text.primary,
            display: 'flex',
            flexGrow: 1,
          })}
          maxWidth={false}
          disableGutters
        >
          <Stack direction='row' flexGrow={1}>
            <Container maxWidth={false} sx={{ position: 'relative' }} disableGutters>
              {setupRoutes(location)}
            </Container>
          </Stack>
        </Container>
        <Footer />
      </Stack>
    </Box>
  )

  return (
    <>
      {wrapInTheme(
        <ModalContextProvider>
          <ConfirmModalContextProvider>
            <ItemMenuContextProvider>{body}</ItemMenuContextProvider>
          </ConfirmModalContextProvider>
        </ModalContextProvider>,
      )}
    </>
  )
}

export default App
